@import "./src/styles/abstracts/_colors";
@import "./src/styles/abstracts/mixin";

.user {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    --size: 32px;
    --color: #{$SILVER};
    position: relative;
    width: var(--size);
    height: var(--size);
    font-size: calc(var(--size) / 1.8);
    border-radius: 50%;
    background-color: var(--color);
    color: $WHITE;

    &--with-border {
        border: 2px solid transparent;
    }

    // sizes
    &--xs {
        --size: 18px;
    }

    &--sm {
        --size: 29px;
    }

    &--md {
        --size: 35px;
    }

    &--lg {
        --size: 40px;
    }

    &--xl {
        --size: 70px;
    }

    &--xxl {
        --size: 100px;
    }

    &__photo {
        @include imageCenter;

        flex-shrink: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
    }

    &__label {
        position: absolute;
        right: 0;
        top: 0;
        width: 10px;
        height: 10px;
        border: 1px solid $WHITE;
        background-color: $UFO_GREEN;
        border-radius: inherit;
    }

    &__online {
        position: absolute;
        z-index: 1;
        top: -1%;
        right: -5%;
        width: 30%;
        height: 30%;
        border: 1px solid $WHITE;
        background-color: $UFO_GREEN;
        border-radius: 50%;
    }
}
