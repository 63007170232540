@import "../../../styles/abstracts/colors";
@import "../../../styles/abstracts/mixin";

.account-popup {
    &:not(&--mobile) {
        padding: 0 8px;
        position: absolute;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.13);
        min-width: 250px;
        border-radius: 2px;
        right: 0;
        z-index: 1;
        top: 35px;
    }

    &--light {
        background-color: $WHITE;

        .account-popup__btn {
            color: $SCARPA_FLOW;
        }

        .account-popup__name {
            color: $BLACK_PEARL;
        }

        .account-popup__item {
            border-color: $GALLERY;
        }
    }

    &--dark {
        background-color: $ONYX;

        .account-popup__btn {
            color: $IRON;
        }

        .account-popup__name {
            color: $WHITE;
        }

        .account-popup__item {
            border-color: $SHARK;
        }
    }

    &__item {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        padding: 8px 0 8px 16px;
        cursor: pointer;

        &:first-child,
        &:last-child {
            padding-top: 16px;
            padding-bottom: 16px;
        }

        &:first-child {
            border-bottom-width: 1px;
            border-bottom-style: solid;
            margin-bottom: 8px;
            padding-left: 9px;

            @include mediaMax(768px) {
                padding-left: 16px;
            }
        }

        &:last-child {
            border-top-width: 1px;
            border-top-style: solid;
            margin-top: 8px;
        }

        // &::after {
        //     content: "";
        //     display: none;
        //     position: absolute;
        //     right: 0;
        //     bottom: 0;
        //     width: 100%;
        //     height: 1px;
        //     background-color: $GALLERY;
        // }

        // &:hover::after {
        //     display: block;
        // }
   
    }

    &__btn {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        font-size: 14px;
        width: 100%;
        cursor: pointer;
    }

    &__container {
        margin-left: 8px;
    }

    &__name {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
    }

    &__email {
        font-size: 10px;
        line-height: 14px;
        color: $SILVER;
    }

    .ci {
        margin-right: 10px;
        font-size: 16px;
    }
}
