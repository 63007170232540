@import "./src/styles/abstracts/colors";
@import "./src/styles/abstracts/mixin";

.task-list {
    @include dFlex(16px 20px);

    $this: &;

    width: 100%;
    min-width: 1320px;
    height: 100%;
    height: 100vh;
    padding-bottom: 145px;
    height: calc(var(--vh, 1vh) * 100);

    &__modal {
        padding: 24px;
    }

    &__item {
        position: relative;
        flex: 0 1 25%;
        height: 100%;

        // scroll-snap-align: end;

        &:first-of-type .task-card {
            margin-left: 2px;
        }

        &:last-of-type {
            @include mediaMax(1400px) {
                margin-right: 20px;
            }
        }

        &:last-of-type .task-card {
            margin-right: 1px;
        }

        > .task-card {
            margin-bottom: 16px;
            cursor: default;

            &:hover {
                box-shadow: 0 1px 4px rgb(0 0 0 / 10%);
            }
        }

        #{$this}__container {
            background-color: $WHITE;
        }

        &--dark {
            .task-list__container {
                background-color: $ONYX;
            }

            .task-card__headline {
                color: $IRON;
            }

            #{$this}__content {
                background-color: $ONYX;
            }

            .form__input {
                background-color: $SHARK !important;
                border-color: $GUNSMOKE !important;
            }

            .task-card__button-draft {
                background-color: $GUNSMOKE;
                border: none;
            }

            .task-card__button-draft:hover {
                background-color: $ONYX;
            }
        }
    }

    &__content {
        width: 100%;
        height: 154px;
        display: flex;
        padding: 16px;
        margin-left: 1px;
        margin-bottom: 16px;
        border-radius: 2px;
        background-color: $WHITE;
        text-align: center;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

        & > * {
            flex-basis: 100%;
        }
    }

    .form__label--custom {
        margin-bottom: 16px;
    }

    .form__fieldset {
        margin-bottom: 0;
    }

    &__btn {
        width: 30px;
        height: 20px;
        transform: translateY(1px);
        background-color: $UFO_GREEN;
        border-radius: 4px;
        border: 1px solid $UFO_GREEN;
        color: $WHITE;
        cursor: pointer;
    }

    .form__btn {
        margin-top: 14px;
    }

    &__container {
        padding: 16px;
        margin: auto;
        border: none;
        background-color: $ZIRCON;
        border-radius: 2px;
        cursor: pointer;

        .form__fieldset {
            margin-bottom: 0;
        }

        .form__btn {
            margin-top: 8px;
        }
    }

    &__stage {
        height: 2px;
        top: 0;
        left: 0;
        width: 100%;
        display: block;
        position: absolute;
        z-index: 1;
        border-radius: 2px 2px 0 0;

        &--new {
            background-color: $SILVER;
        }

        &--pending {
            background-color: $BRIGHT_CERULEAN;
        }

        &--inProcess {
            background-color: $TULIP_TREE;
        }

        &--checking {
            background-color: $UFO_GREEN;
        }
    }

    &#{$this}--alternate {
        #{$this}__stage {
            &--pending {
                background-color: $SILVER;
            }

            &--inProcess {
                background-color: $BRIGHT_CERULEAN;
            }

            &--checking {
                background-color: $TULIP_TREE;
            }

            &--approval {
                background-color: $UFO_GREEN;
            }
        }
    }
}
