@import "styles/fonts/_fonts";
@import "styles/fonts/_coolicons";
@import "styles/abstracts/_colors";
@import "styles/abstracts/_mixin";
@import "styles/abstracts/_form";
@import "styles/abstracts/_rc-tooltip";
@import "../node_modules/rc-tooltip/assets/bootstrap_white.css";

// @import "styles/abstracts";
// @import "components";

html {
    --header-height: 80px;
    --section-header-height: 35px;
    box-sizing: border-box;
    font-size: 22px;
    font-size: 16px;
    font-weight: 400;
}

* {
    @include custom-scroll-bar;

    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html,
body {
    height: 100%;
}

body {
    --scroll-thumb-color: #{$LIGHT_GREY};
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.theme--dark * {
        --scroll-thumb-color: #717578;
    }

    &.theme--dark {
        $darkColor: #828588;

        .tox .tox-split-button:focus {
            background-color: transparent !important;
        }

        .tox .tox-collection--toolbar .tox-collection__item--active {
            background-color: $LIGHT_GREY !important;
            cursor: pointer !important;
        }

        .tox-toolbar__overflow {
            background-color: #1e1e1e !important;
        }

        .tox-tbtn {
            background-color: transparent !important;
        }

        .tox-tbtn svg {
            fill: #fff !important;
        }

        .tox-tbtn.tox-tbtn--enabled svg {
            fill: $BLUE_DE_FRANCE !important;
        }

        .tox-menu svg {
            color: #fff !important;
        }

        .tox-menu {
            background-color: #1e1e1e !important;
        }

        .message__file-icon path {
            fill: #C4C4C4;
        }

        .settings-panel__item path,
        .header__item--balance path {
            fill: $darkColor;
        }

        .task-card__level {
            background-color: $darkColor;
        }

        .header__item--balance circle {
            fill: transparent;
        }

        .task-card__headline {
            color: $darkColor !important;

            .task-card__counter {
                color: inherit;
                border-color: currentColor;
            }
        }

        .message__file-name {
            color: #C4C4C4;
        }

        .task-card__title,
        .task-card__id,
        .task-card__attached-files,
        .settings-panel__balance,
        .header__balance,
        .section__input-title {
            color: $darkColor !important;
        }

        .task-loading {
            color: rgba($WHITE, 0.1);
        }

        .input__field {
            color: $WHITE;
            background-color: $ONYX;
        }

        .ql-editor,
        .commercial-offer-check__item-ttl--md,
        .payment-block__title,
        .personal-cabinet__sub-title,
        .react-tabs__tab:not(.react-tabs__tab--selected) {
            color: $WHITE;
        }

        .description-task__content.ql-editor > p > span {
            color: $WHITE_SMOKEY_DARK !important;
        }

        .custom-select__menu {
            background-color: $ONYX;
        }

        .custom-select__option {
            &--is-selected,
            &--is-focused {
                background-color: $DARK_VIOLET;
                color: $WHITE;
            }
        }
    }
}

a {
    color: $BLUE_DE_FRANCE;
}

img {
    display: block;
}

fieldset {
    border: none;
    margin: 0;
    padding: 0;
}

label {
    cursor: pointer;
}

textarea {
    width: 100%;
    resize: none;
    border: none;
    outline: none;
    background-color: transparent;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;

    &:hover,
    &:focus {
        // cursor: pointer;
   
    }
}

input, textarea, button {
    font-family: 'Noto Sans', sans-serif;
}

#root {
    display: flex;
    height: auto;
    width: 100%;
    min-height: 100vh;
}

.list {
    list-style-type: none;

    &--scroll {
        height: auto;
        max-height: 100vh;
        height: calc(100% - 64px);

        // scroll-padding: 20px;

        // scroll-snap-type: y mandatory;

        &::-webkit-scrollbar {
            height: 100px;
            padding-top: 20px;
        }

        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &--active {
            @include custom-scroll-bar(var(--scroll-thumb-color), transparent, 14px);

            overflow-y: auto;
            padding-right: 4px;
            margin-right: -10px;
            margin-left: -1px;
            padding-left: 2px;
            margin-left: -6px;
            padding-left: 7px;
        }
    }

    &__item {
        // scroll-snap-align: end;
   
    }

    &__item:not(:last-of-type) {
        margin-bottom: 16px;
    }

    &__item:last-of-type {
        margin-bottom: 14px;
    }
}

.section {
    $this: &;

    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    background: url("./media/images/bg.svg") right 40px top no-repeat, url("./media/images/bg--bottom.svg") left bottom no-repeat;
    background-size: 40%;

    .section__input-title {
        max-height: 60px;
        padding-right: 5px;
        margin-right: 32px;
        flex: 1;
        border-radius: 2px;
        box-sizing: content-box;
        border: 1px solid transparent;

        &:hover {
            // background-color: rgb(0 0 0 / 8%);
       
        }

        &:active,
        &:focus {
            // border-color: $SILVER;
            // background-color: $WHITE;
       
        }
    }

    &--light {
        .section__title,
        .section__input-title {
            color: $SCARPA_FLOW;
        }
    }

    &--dark {
        background-color: $SHARK;

        .section__title,
        .section__input-title {
            color: $IRON;
        }

        .section__icon-btn {
            background-color: $ONYX;
            color: $IRON;
        }

        .form__input {
            background-color: $ARSENIC;
            border-color: $ARSENIC;
            color: $IRON;
        }

        .form__label--custom {
            color: $IRON;
        }
    }

    &__logo {
        display: block;
        margin: 28px 0;

        img {
            margin: 0 auto;
        }
    }

    &__wrap-auth {
        max-width: 360px;
        margin: auto;
        width: calc(100% - 32px);
    }

    &__auth-form {
        background: rgba(255, 255, 255, 0.1);
        box-shadow: -10px 0px 63px rgba(0, 0, 0, 0.08);
        backdrop-filter: blur(20px);
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        padding: 24px 20px 32px;

        &--login {
            min-height: 307px;
        }

        .form__title:not(.form__title--restore) {
            height: 44px;
        }

        .btn {
            width: 100%;
            margin-top: auto;
        }

        .form__fieldset {
            margin-top: auto;
        }

        .form__label {
            height: 22px;
        }

        .form__input {
            height: 36px;
        }

        form {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &--custom {
            padding-left: 32px;
            padding-right: 32px;
        }
    }

    &__auth-text {
        font-size: 14px;
        line-height: 19px;
        color: $SILVER;
        text-align: center;
        margin: 8px auto;
        display: block;
    }

    &__auth-img {
        display: block;
        margin: 0 auto 16px;
        font-size: 120px;
        color: $SILVER;
    }

    &__auth-description {
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: $SCARPA_FLOW;
    }

    &__extra-info {
        font-size: 14px;
        line-height: 22px;
        color: $BLACK_PEARL;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px 0;
    }

    &__header {
        position: sticky;
        left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;

        // margin-bottom: 16px;
        // height: var(--section-header-height);
   
    }

    &__container {
        align-self: flex-start;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mediaMin(768px) {
            min-width: 200px;
        }
    }

    &__icon-btn {
        background-color: $WHITE;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        border: none;
        width: 40px;
        height: 32px;
        font-size: 21px;
        margin-left: 16px;
        cursor: pointer;
        color: $SCARPA_FLOW;

        &:first-of-type {
            margin-left: 24px;
        }
    }

    &__add-user {
        border: none;
        cursor: pointer;
        background-color: transparent;
        height: 35px;
        position: relative;
        margin-left: 8px;

        .ci-user_plus {
            position: absolute;
            color: $SILVER;
            font-size: 18px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &__title {
        --offset-right: 30px;
        position: relative;
        display: flex;
        align-items: center;

        // min-width: calc(25vw - var(--offset-right) - 16px);
        margin-right: var(--offset-right);
        margin-bottom: 5px;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
    }

    &__users-wrapper {
        display: flex;
        align-items: center;

        // margin-right: 24px;
        margin-left: auto;

        &--mobile {
            margin-top: 9px;
        }
    }
}

.main {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100vh - var(--header-height));
    padding: 16px 40px 4px 40px;

    // scroll-snap-type: x mandatory;
    // scroll-padding-right: 20px;

    &--tasks {
        overflow-x: auto;
        overflow-y: hidden;
    }
}

@include respond-to(mobile-landscape) {
    .section {
        //background: url("./media/images/bg-top--mobile.svg") right top no-repeat,
        //            url("./media/images/bg-bottom--mobile.svg") left bottom no-repeat;
        //background-size: inherit;
        // background: none;

        &__auth-form {
            padding-left: 16px;
            padding-right: 16px;
        }

        &__extra-info {
            font-size: 14px;
        }
    }

    .main {
        padding-left: 16px;
        padding-right: 16px;
    }
}


.list-wrapper {
    @include custom-scroll-bar(transparent);

    height: 100%;
    margin: 0 -10px;
    padding: 0 10px;

    @media (hover: none) {
        overflow-x: auto;
    }

    &__content {
        // overflow-x: auto;
        // overflow-y: hidden;
   
    }
}

.modal--add-participants {
    max-width: 750px;
    width: 100%;
}

// .quill {
//     margin-top: 16px;
#tinymce {}

.description-task__content {
    img, video {
        max-width: 100%;
        height: auto;
    }
}

.editor--dark .tox-tinymce, .editor--dark .tox-editor-container {
    border: none !important;
}

.tox-tinymce, .tox-editor-container {
    overflow: visible !important;
    border-radius: 0 !important;
}

.editor--dark .tox .tox-menu {
    background-color: #1e1e1e !important;
}

.editor--dark .tox-tbtn__select-label,
.editor--dark .mce-content-body {
    color: #fff !important;
}

.project-card.dragging {
    transform: rotate(40deg);
}

.theme--dark .custom-select__option--is-selected {
    background-color: #1e1e1e !important;
}

.editor--dark .tox-collection__item--enabled {
    background-color: transparent !important;
    width: 100% !important;
}

.editor--dark .tox .tox-tbtn svg {
    // width: 18px !important;
    // height: auto !important;
    fill: #fff;
}

.tox:not(.tox-tinymce-inline) .tox-editor-header {
    padding-top: 2px !important;
    padding-bottom: 1px !important;
}

.tox .tox-toolbar__group {
    padding-right: 7px !important;
    padding-left: 8px !important;
}

.tox .tox-tbtn {
    width: min-content !important;
    padding-left: 3px;
    padding-right: 3px;
}

.editor--dark .tox .tox-tbtn {
    background-color: initial !important;
}

.editor--dark .tox-toolbar-overlord {
    background-color: initial !important;
}

.editor--dark .tox-editor-header,
.editor--dark .tox-toolbar__primary {
    background-color: #1e1e1e !important;
    border-color: #1e1e1e !important;
}

// .tox .tox-tinymce {
// }

.tox .tox-tbtn {
    cursor: pointer;
}

.ql-editor.ql-editor {
    // .ql-editor.ql-editor {
    font-size: 14px;
    color: $DARK_VIOLET;
    min-height: 224px;
    padding-top: 16px;
    padding-bottom: 15px;
    cursor: auto;

    h1, h2, h3 {
        margin-top: 24px;
        margin-bottom: 8px;
    }

    h1 {
        margin-top: 20px;
        font-size: 24px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 16px;
    }

    ul, ol {
        margin-top: 16px;
        margin-bottom: 16px;
        padding-left: 0;
    }

    ul:first-child, ol:first-child {
        margin-top: 0;
    }

    ul li, ol li {
        position: relative;
        padding-left: 0;
        display: flex;

        &:not(:last-of-type) {
            margin-bottom: 6px;
        }

        br {
            // display: none;
       
        }
    }

    ol {
        padding-left: 24px;
        padding-left: 15px;

        li::before {
            width: 20px;
            margin-left: 0;
            margin-right: 4px;
            text-align-last: left;
        }
    }

    ul {
        padding-left: 20px;
        padding-left: 11px;

        li {
            display: flex;

            &::before {
                flex-shrink: 0;
                width: 21px;
                margin-left: 0;
                margin-right: 0;
                text-align: center;
                font-size: 21px;
                line-height: 1;
            }
        }
    }

    // p:not(:empty) + p:not(:empty) {
    //     margin-top: 16px;
    // }

    p a {
        // display: block;

        // margin-top: -6px;
        margin-top: 10px;
        color: $BLUE_DE_FRANCE_DARK;
        text-decoration: none !important;
        cursor: pointer;
    }

    p {
        margin-top: 1em;
        margin-bottom: 1em;
        line-height: 1.357;
    }
}

.tox .tox-edit-area::before {
    border-color: transparent;
}

// }
.screen-loader-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.task-loading {
    margin: auto;
    color: rgba($BLACK_PEARL, 0.1);
}

.settings-panel {
    min-height: 51px;
}

.create-negayno {
    $this: &;

    // position: absolute;
    // padding-top: 10px;
    position: relative;
    top: -5px;
    margin-top: 10px;
    right: 0;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;

    // margin-top: 14px;
    margin-right: 4px;

    &__label {
        position: relative;
        margin-right: 24px;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;

        &::after {
            content: '';
            --size: 12px;
            --color: #C1C1C1;
            position: absolute;
            top: 50%;
            right: calc(-1 * var(--size) - 18px);
            transform: translateY(-50%);
            width: var(--size);
            height: var(--size);
            background-color: var(--color);
            border: calc(var(--size) / 5) solid var(--color);
            border-radius: 50%;
            background-clip: content-box;
            padding: calc(var(--size) / 4);
        }
    }

    &__checkbox {
        display: none;

        &:checked + #{$this}__label::after {
            --color: red;
        }
    }
}

.confirm-modal {
    padding: 20px;

    &__textarea {
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid $SILVER;
        font-size: 16px;
        font: 400 14px/1.2 'Noto Sans', sans-serif;

        &::placeholder {
            font: inherit;
        }

        &--error {
            border-color: $RED;

            &::placeholder {
                color: $RED;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;
    }
}

.gallery-top-block {
    width: auto;
    justify-self: inherit;
}
