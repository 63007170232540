.users-list {
  display: flex;
  justify-content: center;
  align-items: center;

  &__item {
    position: relative;
    margin-left: 2px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }
  }
}

