@import "./src/styles/abstracts/colors";

.balance {
    display: flex;
    align-items: center;
    
    font-size: 14px;
    line-height: 19px;
    text-decoration: underline;
    transition: 0.2s color;

    &--light {
        color: $BLACK_PEARL;
    }

    &--dark {
        color: $IRON;
    }

    &:hover {
        color: $BLUE_DE_FRANCE;
    }

    &__money {
        display: block;
        min-width: 60px;
        font-weight: 700;
        margin-left: 5px;
    }
}
