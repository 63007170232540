@import "../../styles/abstracts/_colors.scss";
@import "../../styles/abstracts/_mixin.scss";

.notification {
    $this: &;

    @include box-shadow;

    overflow: hidden;
    z-index: 999;
    position: absolute;
    top: 60px;
    right: 0;
    width: 100%;
    max-width: 365px;
    background-color: $WHITE;

    &--dark {
        background-color: $ONYX;
        color: $SILVER;

        #{$this}__filter {
            color: $WHITE;
        }
    }

    &__top {
        @include dFlex(16px);

        position: relative;
        flex-wrap: wrap;
        padding: 16px 16px 8px 16px;
    }

    &__bottom {
        overflow-y: auto;
        max-height: 423px;

        @include mediaMax($tablet) {
            max-height: 100vh;
        }
    }

    &__title {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
    }

    &__empty {
        padding: 24px;
    }

    &__menu {
        position: absolute;
        z-index: 1;
        top: 50px;
        right: 20px;
        max-width: 341px;
        width: 100%;
        padding: 8px 0;
        background-color: $WHITE;
        border-radius: 2px;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }

    &__menu-item {
        display: flex;
        align-items: center;
        column-gap: 8px;
        padding: 8px 16px;
        border-radius: 2px;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            background: rgba(63, 140, 255, 0.06);
        }
    }

    &__menu-button {
        display: flex;
        padding: 0;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &--active,
        &:hover {
            background-color: #FAFBFC;
        }
    }

    &__filters {
        @include dFlex(16px);
    }

    &__filter {
        // color: $DARK_VIOLET;
   
    }
}

.notification-item {
    $this: &;

    @include dFlex(16px);

    @include mediaMin($tablet) {
        padding: 24px 32px 24px 16px;
    }


    @include mediaMax($tablet) {
        padding: 10px 29px 10px 15px;
    }


    position: relative;
    flex-wrap: wrap;
    background-color: $SOFT_BLUE;
    cursor: pointer;

    &:hover {
        background-color: $LIGHT_BLUE;
    }

    // variants
    &--viewed {
        background-color: $WHITE;

        &:hover {
            background-color: $WHITE_SMOKEY;
        }
    }

    &--dark {
        background-color: $ONYX;
        color: $SILVER;

        #{$this}__title {
            color: $SILVER;
        }

        &:hover {
            background-color: $ARSENIC;
        }
    }

    // elements
    &__breadcrumbs {
        flex-basis: 100%;
    }

    &__avatar {
        flex-shrink: 0;
    }

    &__texts {
        @include dGrid(8px);

        flex: 1;
        width: 100%;
    }

    &__title {
        position: relative;
        color: #0C182A;
        font-size: 14px;
        font-family: 'Noto Sans';
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        // word-break: break-all;
        hyphens: auto;
    }

    &__text {
        color: #C1C1C1;
        font-size: 14px;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        &#{$this}__text--accent {
            color: $SOFT_RED;
        }
    }

    &__status-marker {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%);
    }

    &__date {
        word-break: break-all;
        font-size: 14px;
        color: $SILVER;
    }
}

.breadcrumbs {
    @include dFlex;

    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid $GALLERY;

    &__link {
        position: relative;
        display: inherit;
        align-items: center;
        text-decoration: none;
        color: $SCARPA_FLOW;
        font-size: 12px;
        font-family: Noto Sans;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:not(:last-of-type)::after {
            content: '';
            display: block;
            width: 12px;
            height: 12px;
            margin-left: 2px;
            margin-right: 2px;
            background: url("../../media/icons/chevron_right.svg") no-repeat (center / 14px);
        }
    }
}
