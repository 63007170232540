@import "./src/styles/abstracts/_colors";

.progressbar {
  display: flex;
  align-items: center;
  width: 100%;

  &__container {
    flex: 1;
    height: 20px;
    background-color: $ATHENS_GRAY;
    border-radius: 2px;
    overflow: hidden;
    position: relative;
  }

  &__filler {
    transition: width 2s;
    height: inherit;
    border-radius: inherit;
    overflow: hidden;
  }

  &__filler-bg {
    height: inherit;
    transition: width 2s;
    background: linear-gradient(90deg, #F09819, #BE0000);
  }

  &__text {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: $BLACK_PEARL;
    position: absolute;
    left: 50%;
    z-index: 1;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
