@import "./src/styles/abstracts/_colors";
@import "./src/styles/abstracts/_mixin";

.chat {
    $this: &;

    --offset-top: 42px;
    --footer-height: 46px;
    position: relative;
    z-index: 1;

    // height: calc(100vh - var(--offset-top));

    &--dark {
        background-color: $ONYX;

        #{$this}__header {
            background-color: $ARSENIC;
        }

        #{$this}__footer {
            border-color: $ONYX;
        }

        #{$this}__input-wrap {
            background-color: $ARSENIC;
        }

        #{$this}__input {
            color: $WHITE;
        }

        #{$this}__reply {
            background-color: $DARK_JUNGLE_GREEN;
        }

        #{$this}__reply-title,
        #{$this}__text,
        #{$this}__title {
            color: $SILVER;
        }

        #{$this}__reply {
            background-color: $ARSENIC;
            border-bottom: 1px solid $ONYX;
        }
    }

    &__header {
        position: sticky;
        z-index: 1;
        padding: 16px 11px 0 24px;
        top: 0;
        left: 0;
        right: 0;
        background-color: #f6f6f6;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        padding-right: 2px;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);

        // height: calc(100vh - 20px);
   
    }

    &__wrapper {
        height: 100%;
    }

    &__manager {
        text-align: center;
    }

    &__top-block {
        margin-top: auto;
    }

    &__body {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        padding: 0 4px 0 11px;
        height: calc(100vh - var(--offset-top));
        height: calc(100dvh - var(--offset-top));

        // max-height: calc(100vh - 70px);

        &::-webkit-scrollbar {
            width: 6px;
        }

        &--manager {
            background-color: $LIGHT_GREEN;
        }
    }

    &__scroll-btn {}

    &__message--last {
        padding-bottom: 10px;
    }

    &__reply {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 8px;
        width: 100%;
        height: 46px;
        padding: 8px 41px 8px 17px;
        background-color: rgba(#3F8CFF, 0.059);

        > * {
            flex-shrink: 0;
        }
    }

    &__title {
        &--active {
            color: $BLUE_DE_FRANCE;
        }
    }

    &__reply-info {
        width: calc(100% - 44px);
        margin-right: auto;

        &--hide-avatar {
            display: none;
        }
    }

    &__reply-text {
        @include textOverflow;
    }

    &__buttons {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    &__button {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        cursor: pointer;

        svg path {
            fill: currentColor;
        }

        &--attachments {
            margin-right: 8px;
        }
    }

    &__button--edit {
        cursor: default;

        path {
            fill: $BLUE_DE_FRANCE;
        }
    }

    &__button--attachments {
        margin-bottom: 3px;
    }

    &__button--send-message {
        width: 38px;
        height: 32px;
    }

    &__button--attachments,
    &__button--emojies,
    &__button--close,
    &__button--reply {
        width: 24px;
        height: 24px;
    }

    &__button--reply {
        cursor: default;
    }

    &__footer {
        position: relative;
        margin-right: -2px;
        border-top: 1px solid $GALLERY;
        background-color: $WHITE;
    }

    &__input-wrap {
        position: relative;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 10px 12px 10px 10px;
        background-color: $WHITE;
    }

    &__input {
        overflow-y: auto;
        margin-right: auto;
        width: 100%;
        max-height: 230px;
        margin-right: 16px;
        align-self: center;
        border: none;
        font-size: 14px;
        line-height: normal;
        outline: none;
        font-weight: 400;

        &:hover,
        &:focus {
            cursor: text;
        }

        &::placeholder {
            user-select: none;
            font-size: 14px;
            color: $SILVER;
        }
    }

    &__reply-title {
        color: $DARK_VIOLET;
        font-size: 10px;
    }

    &__reply-text {
        word-break: break-all;
    }

    &__reply-img-thumb {
        width: 29px;
        height: 29px;
        object-fit: cover;
        object-position: center;
        border-radius: 2px;
    }

    &__reply-file-thumb {
        display: flex;
        align-items: center;
    }

    &__smiles {
        z-index: 9;
        position: absolute;
        right: 100px;
        bottom: 40px;
    }

    #{$this}__spinner {
        @include posCenter;

        color: $SILVER;
    }
}

.context-menu {
    $this: &;

    --width: 222px;
    position: absolute;
    z-index: 9999;
    left: 0;
    top: 0;
    display: none;
    width: var(--width);
    height: fit-content;
    padding: 12px;
    background-color: $WHITE;
    border-radius: 4px;
    opacity: 0;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.13);
    animation: op 0s ease-in-out forwards 0.01s;

    &--dark {
        background-color: #505762;

        #{$this}__item {
            color: $SILVER;
        }

        #{$this}__item {
            color: $WHITE;
        }
    }

    @include mediaMax(768px) {
        --width: 144px;
    }


    @keyframes op {
        to {
            opacity: 1;
        }
    }

    &--show {
        display: block;
    }

    &__item {
        display: flex;
        align-items: center;
        column-gap: 12px;
        color: $DARK_VIOLET;
        font-size: 14px;
        cursor: pointer;

        &:not(:last-of-type) {
            margin-bottom: 12px;
        }

        svg {
            flex-shrink: 0;

            path {
                fill: currentColor;
            }
        }

        &:hover {
            color: $BLUE_DE_FRANCE;
        }
    }

    #{$this}__smiles {
        overflow-y: hidden;
        width: 100%;
        padding: 0;
        justify-content: space-between;
        margin-bottom: 12px;
        box-shadow: none;
        margin-bottom: 18px;
    }

    &__smile {
        width: 28px;
        font-size: 20px;
        flex: 0 1 28px;
        line-height: 1.2;
    }
}

.btn-scroll-top {
    position: absolute;
    z-index: 999;
    right: 30px;
    bottom: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.13);
    cursor: pointer;
    transition: background-color 0.3s;
    background-color: $WHITE;

    &:hover {
        background-color: $GALLERY;
    }

    &::after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        inset: 0;
        margin: auto;
        top: -4px;
        border-right: 1px solid $DARK_VIOLET;
        border-top: 1px solid $DARK_VIOLET;
        transform: rotate(135deg);
    }

    &--dark {
        background-color: $ONYX;

        &::after {
            border-color: $WHITE;
        }
    }

    &__counter {
        position: absolute;
        top: -5px;
        left: 50%;
        padding: 2px 3px;
        min-width: 20px;
        height: 20px;
        transform: translate(-50%, -50%);
        color: $WHITE;
        font-size: 12px;
        font-weight: 500;
        border-radius: 25px;
        background-color: #3F8CFF;
    }
}
