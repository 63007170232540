.spinner {
    --color: #fff;
    --size: 80px;
    position: absolute;
    width: var(--size);
    height: var(--size);
    color: var(--color);

    &:after {
        content: " ";
        display: block;
        width: calc(var(--size) * 0.8);
        height: calc(var(--size) * 0.8);
        border-radius: 50%;
        border: calc(var(--size) / 10) solid;
        border-color: currentColor transparent currentColor transparent;
        animation: spinner 1.2s linear infinite;
    }

    &--xs {
        --size: 20px;
    }

    &--sm {
        --size: 40px;
    }

    &--md {
        --size: 80px;
    }

    &--lg {
        --size: 120px;
    }

    &--center {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
}
