@import "_colors";

.rc-tooltip {
    $this: &;

    border-color: $SHARK;
    background-color: transparent;

    &-placement {
        &-top {
            // transform: translateX(20%);
       
        }

        &-topRight #{$this}-arrow,
        &-top #{$this}-arrow {
            border-top-color: $SHARK;
            bottom: -4px;
        }

        &-left #{$this}-arrow {
            border-left-color: $SHARK;
        }

        &-right #{$this}-arrow {
            border-right-color: $SHARK;
        }

        &-bottom #{$this}-arrow {
            border-bottom-color: $SHARK;
        }

        &-bottomLeft #{$this}-arrow {
            border-bottom-color: $SHARK;
        }

        &-bottomRight #{$this}-arrow {
            border-bottom-color: $SHARK;
        }

        &-topLeft #{$this}-arrow {
            border-top-color: $SHARK;
        }
    }

    #{$this}-arrow {
        display: none;
    }

    &-inner {
        min-height: 20px;
        padding: 2px 8px;

        // padding: 0;
        color: $WHITE;
        text-align: center;
        background-color: $SHARK;
        border-radius: 4px;
        border: none;
        font-size: 12px;

        // line-height: 14px;
   
    }
}

.theme--dark {
    .rc-tooltip {
        $this: &;

        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);

        &-inner {
            background-color: #505762;
            color: #fff;
        }

        &.rc-tooltip-placement-top {
            .rc-tooltip-arrow {
                border-top-color: #505762;
            }
        }

        &.rc-tooltip-placement-bottom {
            .rc-tooltip-arrow {
                border-bottom-color: #505762;
            }
        }

        &.rc-tooltip-placement-left {
            .rc-tooltip-arrow {
                border-left-color: #505762;
            }
        }

        &.rc-tooltip-placement-right {
            .rc-tooltip-arrow {
                border-right-color: #505762;
            }
        }
    }
}
