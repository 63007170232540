.project-list {
    --item-max-width: 200px;
    --column-gap: 16px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(max(calc(25% - var(--column-gap)), var(--item-max-width)), 1fr));
    grid-auto-rows: 138px;
    // height: 100%;
    gap: var(--column-gap) 20px;

    margin: -20px -20px 0;
    padding: 20px 20px 5px;
}
