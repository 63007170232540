.text {

    &--xxl {
        font-size: 2.5rem;
    }

    &--xl {
        font-size: 2rem;
    }

    &--lg {
        font-size: 1.5rem;
    }

    &--md {
        font-size: 1.25rem;
    }

    &--sm {
        font-size: 0.75rem;
    }

    &--xs {
        font-size: 0.75rem;
    }

    &--inline {
        display: inline-block;
    }
}