@import "./../../styles/abstracts/_colors";

.react-tabs {
    -webkit-tap-highlight-color: transparent;

    &__tab-list {
        border-bottom: 1px solid $GALLERY;
        margin: 0 0 10px;
        padding: 0;
    }

    &__tab {
        display: inline-block;
        border-bottom: 2px solid transparent;
        bottom: -1px;
        position: relative;
        list-style: none;
        padding: 8px 16px;
        cursor: pointer;
        font-size: 16px;
        line-height: 22px;
        color: $BLACK_PEARL;

        &--selected {
            background-color: transparent;
            border-color: $BLUE_DE_FRANCE;
            color: $BLUE_DE_FRANCE;
            border-radius: 1px;

            // font-weight: 700;
            text-shadow: 0 0 0;
        }

        &--disabled {
            color: GrayText;
            cursor: default;
        }

        &:focus {
            outline: none;

            &:after {
                content: "";
                position: absolute;
                height: 5px;
                left: -4px;
                right: -4px;
                bottom: -5px;

                // background: #fff;
           
            }
        }
    }

    &__tab-panel {
        display: none;

        &--selected {
            display: block;
        }
    }
}
