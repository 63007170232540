@import "./src/styles/abstracts/colors";

.participants {
    $this: &;

    overflow-x: auto;
    display: grid;
    grid-template-columns: 1fr 220px 40px;
    grid-gap: 16px 25px;
    padding-bottom: 10px;

    &--dark {
        #{$this}__name {
            color: $WHITE;
        }
    }

    &__users-info {
        margin-left: 14px;
    }

    #{$this}__user-btn {
        margin-left: 0;
    }

    &__text {
        display: flex;
        align-items: center;
    }

    &__name {
        width: 100%;
        font-size: 14px;
        line-height: 19px;
        color: $BLACK_PEARL;

        &--custom {
            color: $CORNFLOWER;
        }
    }

    &__select {
        width: 100%;
    }
}
