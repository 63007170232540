.skeleton {
    padding-top: 30px;

    &__item {
        line-height: 1;
        display: block;
        overflow: hidden;
        position: relative;
        width: 50%;
        height: 100%;
        border-radius: 5px;
        background-color: rgb(239, 241, 246);
        margin: 0 auto 10px auto;

        &--animated::before {
            content: "";
            position: absolute;
            height: 100%;
            width: 500px;
            top: 0;
            left: -500px;
            background-image: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
            animation: progress 1.2s ease-in-out infinite;
        }
    }
}

@keyframes progress {
    0% {
        left: -500px;
    }

    100% {
        left: 100%;
    }
}
