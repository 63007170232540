@import "./src/styles/abstracts/_colors";

.btn {
    border-radius: 2px;
    padding: 8px 10px;
    font-size: 14px;
    line-height: 14px;
    border: 1px solid transparent;
    height: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    // sizes

    &--xs {
        padding: 4px 6px;
        font-size: 12px;
        height: 24px;
    }

    &--sm {
        padding: 6px 10px;
        font-size: 12px;
        height: 32px;
    }

    &--md {
        padding: 8px 10px;
        font-size: 14px;
        height: 40px;
    }

    &--lg {
        padding: 10px 12px;
        font-size: 16px;
        height: 45px;
    }

    &--default {
        background-color: $CORNFLOWER;
        color: $WHITE;

        &:hover,
        &:active {
            background-color: $BLUE_DE_FRANCE_DARK;
        }
    }

    &--secondary {
        background-color: $WHITE;
        border-color: $GALLERY;
    }

    &--third {
        background-color: $SILVER;
        border-color: $SILVER;
        color: $WHITE;

        &:hover,
        &:active {
            background-color: $DARK_GREY;
            border-color: $DARK_GREY;
        }
    }

    &--warning {
        --bg: #{$CARNATION};
        background-color: $WHITE;
        border-color: $CARNATION;
        color: $CARNATION;

        &:not(.btn--outline):hover {
            background-color: darken($CARNATION, 10%);
        }
    }

    &--connect {
        background-color: rgba(63, 140, 255, 0.1);
        border-color: $BLUE_DE_FRANCE;
        color: $BLACK_PEARL;
    }

    &--base {
        background-color: $SOFT_BLUE;
    }

    &--success {
        color: $WHITE;
        background-color: $UFO_GREEN;

        &:hover {
            background-color: $SUCCESS_COLOR;
        }
    }

    &--fill {
        background-color: var(--bg);
        color: $WHITE;
    }

    &--accent {
        background-color: $TULIP_TREE;
        color: $WHITE;

        &:hover {
            background-color: $TULIP_TREE_DARK;
        }
    }
}
