@import "./src/styles/abstracts/_colors";
@import "./src/styles/abstracts/_mixin";

.emoji-block {
    $this: &;
    @include box-shadow;

    overflow: hidden auto;
    display: flex;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    gap: 3px;
    padding: 2px;
    width: 120px;
    max-height: 102px;
    border-radius: 4px;
    color: red;
    background-color: $WHITE;

    &--dark {
        background-color: #505762;

        #{$this}__item {
            color: $SILVER;
        }
    }

    &__item {
        flex: 0 1 calc(25% - 3px);
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: transform 0.1s ease-in-out;

        &:hover {
            transform: scale(1.2);
        }
    }
}
