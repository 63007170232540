@import "../../../styles/abstracts/colors";
@import "../../../styles/abstracts/mixin";

.message {
    --color: transparent;

    $this: &;

    display: flex;
    gap: 9px;
    width: 100%;
    pointer-events: none;

    &:first-of-type {
        margin-top: auto;
    }

    &:not(.message--from-the-same-autor) {
        margin-top: 8px;
    }

    &--dark {
        background-color: $ONYX;

        #{$this}__text,
        #{$this}__title {
            color: $SILVER;
        }

        #{$this}__body {
            background-color: $ARSENIC;
            border-color: $SHARK;
        }

        #{$this}__reactions,
        #{$this}__parent {
            border-color: $SHARK;
        }

        #{$this}__reactions {
            border-color: $SHARK;
            background-color: rgba($BLUE_DE_FRANCE, 0.059);
        }

        #{$this}__parent {
            background-color: rgba($BLUE_DE_FRANCE, 0.2);
        }

        #{$this}__file-img {
            box-shadow: 0 0 1px 1px;
        }
    }

    &--from-the-same-autor {
        margin-top: 2px;
    }

    &__inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        pointer-events: auto;
    }

    &__header {
        width: 40px;
        margin-top: 11px;
    }

    &__body {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        min-height: 58px;
        padding: 12px 12px 8px;
        border-radius: 10px;
        background-color: $WHITE;

        &--file {
            padding-bottom: 12px;
        }

        &--system {
            background-color: #EFEFEF;

            @include mediaMax(768px) {
                background-color: rgb(184, 188, 194);
            }
        }

        &--error {
            background-color: #FFD6D6;
        }
    }

    &__system-avatar {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        margin-top: 6px;
        border-radius: 5px;
        object-fit: cover;

        // box-shadow: 0 0 1px 1px #EFEFEF;

        path {
            fill: #999;
        }
    }

    &__top {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;
        margin-bottom: 12px;
        color: $SILVER;
    }

    &__deleted-content {
        background-color: $WHITE;
        color: $SILVER;
    }

    &__restore-btn {
        margin-left: 8px;
        color: $CORNFLOWER;
        cursor: pointer;
    }

    &__text,
    &__top {
        font-size: 14px;
    }

    &__author {
        flex-shrink: 0;
        color: var(--color);
        font-size: 14px;
        line-height: 1;
    }

    &__description {
        margin-right: auto;
    }

    &__date {
        margin-left: auto;
        font-size: 12px;
        user-select: none;

        @include mediaMax(768px) {
            font-size: 10px;
        }
    }

    &__text {
        flex-basis: 100%;
        white-space: break-spaces;
        color: $DARK_VIOLET;
        word-break: break-word;
    }

    &__text:not(#{$this}__text--file):not(#{$this}__text--parent) {
        margin-top: -4px;
        line-height: 19px;
    }

    &__reactions {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        margin-top: 10px;
        border-radius: 2px;
    }

    &__reaction {
        display: flex;
        align-items: center;
        justify-content: space-between;
        column-gap: 2px;
        max-width: 30px;
        padding-left: 4px;
        background-color: rgba(#3F8CFF, 0.1);
        border-radius: 12px;
        cursor: pointer;
    }

    &__reaction-user {
        --size: 16px;
        position: relative;

        &:not(:last-of-type) {
            margin-right: -25%;
            z-index: 1;
        }
    }

    &__emoji {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 14px;
        font-size: 12px;
        line-height: 1;
        color: red;
    }

    &__parent {
        margin: -12px -12px 12px -12px;
        padding: 12px 12px 0 12px;
        border-top-left-radius: inherit;
        border-top-right-radius: inherit;

        #{$this}__text {
            @include textOverflow;

            cursor: pointer;
        }

        &-quote {
            position: relative;
            top: 14px;
            right: -7px;
            font-size: 34px;
            line-height: 0;
            font-family: serif;
        }

        &-inner {
            display: flex;
            column-gap: 6px;
            padding-top: 1px;
            padding-left: 6px;
            border-left: 2px solid transparent;
        }

        &-inner--text {
            padding-bottom: 2px;
        }

        &-author {
            font-size: 14px;
        }

        &-header {
            user-select: none;
        }
    }

    &__parent-photo {
        width: 40px;
        height: 40px;
        border-radius: 5px;
        object-fit: cover;
        box-shadow: 0 0 1px 1px #DEE2E6;
    }

    &__parent-file-label svg {
        width: auto;
        height: 40px;
    }

    &__date-separator {
        width: max-content;
        padding: 4px 10px;
        margin: 12px auto 8px;
        border-radius: 14px;
        background-color: rgba(15, 31, 56, 0.164);
        color: $WHITE;

        & + #{$this} {
            margin-top: 0;
        }

        @include mediaMax(768px) {
            opacity: 0.5;
        }
    }

    &__bottom {
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__tooltip {
        padding: 4px 8px;
        text-align: left;
        line-height: 1.4;
        transform: translateX(30px) !important;
    }

    &__tooltip-overlay.rc-tooltip-placement-top {
        transform: translateX(25px);

        .rc-tooltip-arrow {
            left: auto;
            right: 10px;
        }
    }

    &__file {
        display: flex;
        align-items: flex-start;
        column-gap: 6px;

        &-img {
            @include imageCenter;

            flex-shrink: 0;
            object-fit: contain;
            width: 76px;
            height: 76px;
            border-radius: 10px;
            box-shadow: 0 0 1px 1px #DEE2E6;
        }

        &-size {
            display: flex;
            align-items: center;
            column-gap: 4px;
            font-size: 11px;
        }

        &-name {
            margin-bottom: 4px;
            font-weight: 600;
            color: $DARK_VIOLET;
        }

        &-button {
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-label svg {
            width: 36px;
            height: auto;
            display: block;
        }

        &-details {
            padding: 2px 0;
        }

        #{$this}__date {
            position: absolute;
            bottom: 8px;
            right: 12px;
            color: $SILVER;
        }
    }

    &__file-icon path {
        fill: $DARK_VIOLET;
    }
}
