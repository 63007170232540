@import "../../../styles/abstracts/colors";
@import "../../../styles/abstracts/mixin";

.range {
    --size: 16px;

    $this: &;

    @include dFlex(2px, column);

    &--dark {
        #{$this}__input {
            @include customRange(14px, #717578, #D5D6D7, #505762, #505762, #D5D6D7);

            --size: 7px;
        }

        #{$this}__text {
            color: $IRON;
        }

        #{$this}__dots {
            #{$this}__dot {
                top: calc(var(--size) + 3.5px);
                background-color: #D5D6D7;
                border-color: #505762;
            }
        }
    }

    &__input {
        @include customRange;

        --size: 7px;
        margin-top: auto;
    }

    &__content {
        position: relative;

        @include dFlex(10px, row, space-between);

        order: -1;
        margin-top: auto;
        width: 100%;
    }

    &__text {
        width: 72px;
        display: none;
        font-size: 10px;
        color: rgba(193, 193, 193, 1);
        cursor: pointer;

        &--active {
            color: rgba(12, 24, 42, 1);
            font-weight: 700;
            display: block;
            position: absolute;
            top: -10px;
        }
    }

    &__dots {
        position: relative;
        width: 100%;
    }

    &__dot {
        --size: 5.3px;
        position: absolute;
        top: calc(var(--size) + 3px);
        width: var(--size);
        border: 1px solid $WHITE;
        border-radius: 50%;
        background-color: $LIGHT_BLUE_DARK;
        pointer-events: none;
        aspect-ratio: 1;

        &:first-of-type {
            transform: translateX(1px);
        }

        &:not(:first-of-type) {
            transform: translateX(-6px);
        }
    }
}
