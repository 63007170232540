@import "../../styles/abstracts/colors";
@import "../../styles/abstracts/mixin";

.file-uploading-short__wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px 20px;
    padding-bottom: 16px;
}

.file-uploading-short {
    padding: 16px 24px 0;

    &__item {
        display: flex;
        align-items: center;
        column-gap: 14px;
    }

    &__left {
        display: flex;
        align-items: center;
        column-gap: 8px;
    }

    &__right {
        display: flex;
        align-items: center;
    }

    &__progress-check {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
    }

    &__name {
        @include textOverflow;

        max-width: 100px;
        flex-shrink: 0;
        padding: 4px 8px;
        display: inline-block;
        border-radius: 2px;
        background: $SILVER;
        color: $WHITE;
        font-size: 12px;
        white-space: nowrap;
    }

    &__time {}

    &__close {
        width: 18px;
        height: 18px;
        cursor: pointer;
    }

    &__progress {
        font-size: 10px;
        color: $SILVER;
    }

    &__progress-bar {
        --posX: 0;
        height: 2px;
        width: 100px;
        background: linear-gradient(to right, $CORNFLOWER var(--posX), $SILVER 0) no-repeat 0 0;
        border-radius: 2px;
    }
}
