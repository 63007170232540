@import "./src/styles/abstracts/colors";

.switcher {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 24px;

  &__input {
    display: none;

    &:checked + .switcher__toggler::before {
      transform: translateX(7px);
      background-color: $GUNSMOKE;
      right: 15px;
      content: "\e9c6";
      color: $WHITE;
    }

    &:checked + .switcher__toggler {
      background-color: $SHARK;
    }
  }

  &__toggler {
    position: absolute;
    cursor: pointer;
    background-color: $GALLERY;
    border-radius: 4px;
    height: 14px;
    width: 30px;
    top: 8px;
    left: 0;
    transition: background-color 0.2s ease;

    &:before {
      position: absolute;
      content: "\e9c2";
      font-family: 'coolicons';
      right: -3px;
      top: -5px;
      width: 24px;
      height: 24px;
      background-color: $WHITE;
      box-shadow: 0 0 4px rgba(0, 0, 0, .08);
      border-radius: 50%;
      transition: transform 0.3s ease;
      color: #544E61;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
