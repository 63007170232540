@import "../../../styles/abstracts/colors";
@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixin";

.custom-select {
    $this: &;

    width: 100%;
    font: 400 14px $baseFont;
    border: 1px solid $GALLERY;
    cursor: pointer;

    &#{$this}--dark {
        background-color: $ONYX;

        #{$this}__single-value,
        #{$this}__placeholder {
            color: $WHITE;
        }

        #{$this}__menu {
            background-color: $ONYX;
        }

        #{$this}__option {
            color: $WHITE;

            &--is-selected {
                background-color: $DARK_VIOLET;
            }

            &--is-focused {
                background-color: $DARK_VIOLET;
            }

            &:not(&--is-selected):hover,
            &:not(&--is-selected):active,
            &:not(&--is-selected):focus {
                background-color: $DARK_VIOLET;
            }
        }
    }

    #{$this}__single-value {
        margin-left: 0;
    }

    #{$this}__placeholder {
        @include textOverflow;

        color: $DARK_VIOLET;
    }

    #{$this}__control {
        min-height: 100%;
        padding: 5px 10px 5px 2px;
        border: none;
        cursor: pointer;
        background: initial;

        &:hover,
        &--is-focused,
        &--menu-is-open {
            border: none;
            box-shadow: none;
        }
    }

    #{$this}__indicator-separator {
        display: none;
    }

    #{$this}__indicator {
        padding: 0;

        svg {
            display: block;
            width: 6px;
            height: 6px;
            fill: none;
            background: linear-gradient(45deg, rgba(193, 193, 193, 1) 50%, transparent 0%);
            transform: translateY(-50%) rotate(-45deg);
        }
    }

    #{$this}__control--menu-is-open > #{$this}__indicators svg {
        transform: translateY(0) rotate(135deg);
    }
}

// this is for apllying styles to the menu if it's opened in portal
.custom-select__menu {
    z-index: 9 !important;
    padding-top: 4px;
    padding-bottom: 4px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.13);
    font: 400 14px $baseFont;

    .custom-select__option {
        z-index: 9;
        padding: 5px;
        color: $GUNSMOKE;
        cursor: pointer;
        text-align: justify;
        word-break: break-all;

        &.custom-select__option--is-selected {
            background-color: $LIGHT_BLUE;
        }

        &.custom-select__option--is-focused {
            &.delete {
                background-color: transparent;
            }
        }

        &:not(&.custom-select__option--is-selected):active {
            background-color: transparent;
        }
    }
}
