@import "../../styles/abstracts/colors.scss";
@import "../../styles/abstracts/mixin.scss";

.gallery {
    &--full {
        .ril-toolbar.ril__toolbar {
            display: none;
        }
    }

    .ril__toolbar {
        justify-content: stretch;
        align-items: center;
        height: 75px;
        margin-right: -13px;
    }

    .ril-close {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .ril-toolbar-left.ril__toolbarItemChild {
        width: 100%;
    }

    .ril-toolbar-left {
        width: 50%;

        .ril__toolbarItem {
            width: 100%;
        }
    }

    .ril-toolbar-right {
        display: flex;

        // width: 100%;
        width: 100px;

        // flex-direction: row-reverse;
        padding-right: 0;
    }

    .ril__toolbarItemChild {
        display: block;
    }

    .ril-zoom-out {
        background: #FFF url("../../media/icons/minus.svg") no-repeat center center;
    }

    .ril-zoom-in {
        background: #FFF url("../../media/icons/plus.svg") no-repeat center center;
    }

    .ril__builtinButton,
    .ril__builtoutButton {
        background-color: #212121;
    }

    .ril__toolbarItem {
        line-height: 1;
    }

    .ril__builtinButton,
    .ril__builtoutButton,
    .ril__toolbarSide,
    .ril__toolbarLeftSide {
        height: 40px;
    }

    .ril__toolbarLeftSide {
        display: flex;
    }

    .ril__toolbarItem:nth-of-type(1) {
        order: 2;
    }

    .ril__toolbarItem:nth-of-type(2) {
        order: 1;
    }

    .ril__closeButton {
        width: 34px;
        height: 34px;
        border-radius: 4px;
        border: 1px solid #E9EBF0;
        background: #FFF url("../../media/icons/close_big2.svg") no-repeat center center;
        opacity: 1;
        margin-right: 10px;
    }

    .zoom-counter {
        order: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        font-size: 14px;
        color: $WHITE;
        background-color: #212121;

        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 16px;
            background-color: $WHITE;
            opacity: 0.2;
        }

        &::before {
            left: 0px;
        }

        &::after {
            right: 0px;
        }
    }
}

.gallery-top-block {
    $this: &;

    display: flex;
    width: 410px;
    align-items: center;
    justify-content: space-between;

    @include mediaMax(992px) {
        width: 50vw;
    }


    &__left,
    &__right {
        display: inherit;
        align-items: center;
    }

    &__left {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: -0.1em;
        color: $WHITE;
    }

    &__right {
        // position: absolute;
        // right: 30%;

        // background-color: #fff;
        margin-right: 8px;
    }

    &__full-size-btn {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        cursor: pointer;
        background-color: #212121;
    }

    &__btn {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #212121;
        cursor: pointer;

        & + & {
            margin-left: 8px;
        }

        &--download {
            &:hover path {
                fill: $BLUE_DE_FRANCE;
            }
        }

        &--delete {
            &:hover path {
                fill: $RED;
            }
        }

        svg path {
            fill: $WHITE;
            transition: fill .1s linear;
        }
    }
}
