@import "../../../styles/abstracts/colors.scss";

.input {
    &__field {
        display: block;
        width: 100%;
        height: 35px;
        padding: 8px 10px;
        font-size: 14px;
        border-radius: 2px;
        border: 1px solid $GALLERY;
        outline: none;

        &::placeholder {
            color: $SILVER;
        }

        &:focus {
            border-color: $BLUE_DE_FRANCE;
        }
    }
}
