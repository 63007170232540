@import "./../../styles/abstracts/_colors";
@import "./../../styles/abstracts/mixin";

.task {
    $this: &;

    display: flex;
    position: relative;
    z-index: 2;
    height: 100%;
    max-width: 1500px;

    @include mediaMax(768px) {
        flex-direction: column;
    }


    &__header {
        z-index: 2;
        position: relative;
        padding: 16px 24px;
        display: flex;
        flex-direction: row-reverse;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        border-bottom: 1px solid $GALLERY;

        .btn:not(:first-of-type) {
            // margin-right: 10px;
       
        }

        &-inner {
            background-color: $ATHENS_GRAY;
        }

        &--mobile {
            padding-left: 50px;
        }
    }

    &__header-button--dark {
        background-color: $ONYX;
        border-color: $ONYX;
        color: $WHITE;
    }

    #{$this}__mobile-top {
        z-index: 2;
        background-color: $WHITE;

        @include mediaMax(768px) {
            top: -18px;
            position: sticky;
            padding: 5px;
            margin-left: -5px;
            margin-right: -5px;
        }


        &::before {
            content: '';
            position: absolute;
            inset: 0;
            top: -20px;
            z-index: -1;
        }
    }

    &__body {
        position: relative;
        z-index: 1;
        overflow-y: auto;
        height: 100%;
        padding: 16px 24px;
        padding-right: 22px;
        margin-right: 2px;
        cursor: default;

        > * {
            position: relative;
        }

        @include mediaMax(768px) {
            padding-right: 16px;
            padding-left: 18px;
        }
    }

    &__public {
        @include mediaMax(430px) {
            position: relative;
            order: 1;
            margin-top: 10px;
        }
    }

    #{$this}__description-toggler {
        --active-bg: #CAD7E6;
        overflow: visible;
        position: relative;
        z-index: 7;
        top: 0px;
        flex-shrink: 0;
        right: 0;
        width: 116px;
        height: 35px;

        &-overlay {
            --offset: 0;
            background-color: $SOFT_GRAY;
            cursor: pointer;

            &::after {
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
            }
        }

        &-task,
        &-chat {
            position: absolute;
            z-index: 2;
            top: 9px;
            pointer-events: none;
        }

        &-chat {
            right: 20px;
        }

        &-task {
            left: 20px;
        }
    }

    &--dark {
        #{$this}__description-toggler {
            --active-bg: #{$ONYX};
        }

        #{$this}__description-toggler-overlay {
            background-color: $GUNSMOKE;

            &::after {
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.08);
                background-color: $GALLERY;
            }
        }
    }

    &__title {
        position: relative;
        display: flex;
        padding-right: 50px;
        color: $BLACK_PEARL;
    }

    &__range {
        flex-shrink: 0;
        height: 28px;
        max-width: 345px;
        margin-right: auto;
        margin-top: auto;
    }

    &__status {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;

        &:before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            margin-right: 4px;
        }

        &--new {
            color: $SILVER;

            &:before {
                background-color: $SILVER;
            }
        }

        &--pending {
            color: $BRIGHT_CERULEAN;

            &:before {
                background-color: $BRIGHT_CERULEAN;
            }
        }

        &--inProcess {
            color: $TULIP_TREE;

            &:before {
                background-color: $TULIP_TREE;
            }
        }

        &--checking {
            color: $UFO_GREEN;

            &:before {
                background-color: $UFO_GREEN;
            }
        }

        &--archive {
            color: $DARK_VIOLET;

            &:before {
                background-color: $DARK_VIOLET;
            }
        }
    }

    &--dark {
        .react-tabs__tab:not(.react-tabs__tab--selected) {
            color: $SILVER;
        }

        #{$this}__move-select {
            background-color: $ONYX !important;
            border-color: $ONYX !important;
        }

        .react-tabs__tab-list,
        #{$this}__header,
        #{$this}__messages-block {
            border-color: $ONYX;
        }

        #{$this}__textarea {
            color: $IRON;
        }

        #{$this}__mobile-top {
            background-color: $ARSENIC;
        }

        #{$this}__line {
            border-color: $ONYX;
        }
    }

    &__column {
        z-index: 5;
        position: relative;

        // flex: 1;
        max-width: 750px;
        max-width: 50%;
        width: 100vw;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);

        @include mediaMax($tablet) {
            width: 100%;
            max-width: 100%;
        }


        &--full {
            max-width: 1000px;

            @include mediaMax($laptop) {
                flex: 0 1 auto;
                width: 100%;
            }
        }

        &--animated {
            max-width: 0;
        }

        &--description {
            --header-height: 68px;
            height: calc(100vh - var( --header-height));
            height: calc(100dvh - var( --header-height));

            @include mediaMax(450px) {
                --header-height: 113px;
            }
        }
    }

    &__mobile-chat {
        height: calc(100dvh - 140px);
        height: calc(100dvh - var(--task-name-height-178, 136px));
        margin-top: -5px;
        background-color: #EFEFEF;
        transform: translateX(100%);
        transition: transform 1s linear 1s;

        &--active {
            transform: translateX(0);
        }

        .chat__inner {
            height: 100%;
        }

        &--in-work .chat__inner {
            height: calc(100% + 15px);
        }
    }

    &__close-btn-mobile {
        left: 30px;
    }

    &__messages-block {
        position: relative;
        background-color: #f6f6f6;
        border-left: 1px solid $GALLERY;
    }

    &__tabs:first-child {
        width: 90%;
    }

    &__textarea {
        position: relative;
        z-index: 2;
        overflow: hidden;
        min-height: auto;
        height: auto;
        margin-right: 12px;
        font-size: 24px;
        line-height: 1.3;
        cursor: auto;

        @include mediaMax(768px) {
            font-size: 20px;
            line-height: 1.25;
        }
    }

    &__line {
        margin-top: 5px;
        margin-bottom: 5px;
        border-bottom-color: $GALLERY;
        border-left: none;
        border-top: none;

        &--mb {
            margin-bottom: 40px;
        }

        &--mb-10 {
            margin-bottom: 10px;
        }
    }

    &__move-block {
        display: flex;
        align-items: center;
        column-gap: 20px;
        padding: 20px;
        color: $DARK_VIOLET;
    }

    &__move-select {
        z-index: 99;
        max-width: 300px;
    }

    &__move-icon {
        position: relative;
        left: -1px;
        pointer-events: none;
        transition: transform .2s linear;
    }

    &__move-btn,
    &__delete-btn {
        transition: border-color 0.1s linear;
    }

    &__move-btn {
        position: relative;

        &:hover {
            border-color: $DARK_VIOLET;
        }
    }

    &__delete-btn {
        margin-left: 16px;
        border-color: $GALLERY;

        &--dark {
            background-color: transparent;
            border-color: currentColor;
            color: $WHITE;

            #{$this}__delete-icon {
                color: $WHITE;
            }
        }

        &:hover {
            border-color: $RED;

            #{$this}__delete-icon {
                color: $RED;
            }
        }
    }

    &__delete-icon {
        color: $SILVER;
        transition: color .2s linear;
    }

    &__select-wrapper {
        @include mediaMax(430px) {
            margin-right: 0;
        }


        position: relative;
        margin-right: 10px;
    }

    #{$this}__move-select {
        position: absolute;
        z-index: 99;
        top: 0;
        right: 0;
        width: 230px;
        height: 35px;
        background-color: $WHITE;
        border: 1px solid #E9EBF0;
        transform: translateX(-44px);
    }

    &__checks {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;

        // gap: 16px 8px;
        gap: 24px;
        margin-bottom: 24px;
    }
}

.delete-block {
    $this: &;

    padding: 24px;

    &__title {
        margin-bottom: 4px;
        color: $SILVER;
        font-weight: 400;
    }

    #{$this}__textarea {
        min-height: 120px;
        padding: 8px 10px;
        margin-bottom: 16px;
        font-size: 14px;
        font-weight: 400;
        color: $DARK_VIOLET;
        border-radius: 2px;
        border: 1px solid $GALLERY;
        background: $WHITE;
        resize: none;
        cursor: text;
    }

    &__wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }

    &--dark {
        #{$this}__textarea {
            background-color: $GALLERY;
            color: $DARK_VIOLET;
        }
    }

    &__button {
        margin-left: auto;

        &--success {
            color: $WHITE;
        }
    }
}
