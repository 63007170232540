@import "./../../../styles/abstracts/_colors";
@import "./../../../styles/abstracts/_mixin";

.description-task {
    $this: &;

    height: 100%;
    max-height: 70%;
    margin-right: -10px;
    padding-right: 10px;

    // overflow-y: auto;

    &--dark {
        .drop-block__title {
            color: $IRON;
        }

        #{$this}__title {
            color: $IRON;
        }

        #{$this}__content,
        #{$this}__item {
            color: #D1D2D3;
        }

        #{$this}__archive-icon path {
            fill: $IRON;
        }

        .custom-select .custom-select__placeholder {
            color: $SILVER;
        }
    }

    &__header {
        display: flex;
        align-items: center;
    }

    &__content {
        padding-bottom: 20px;

        &.ql-editor {
            white-space: initial;
        }
    }

    &__badge {
        margin-top: 20px;
        background-color: #f5b225;
        color: #fff;
        font-size: 11px;
        font-weight: 500;
        display: inline-block;
        padding: .25em .4em;
        border-radius: 0.25em;
    }

    #{$this}__loader {
        --color: red;
        margin: auto;
        text-align: center;
    }

    &__item {
        font-size: 14px;
        line-height: 19px;
        color: $BLACK_PEARL;

        // margin-right: 16px;

        &:first-child {
            margin-right: 16px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__name {
        font-weight: 500;
    }

    &__upload {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: $BLACK_PEARL;
        display: flex;
        align-items: center;
    }

    &__attachments {
        margin-top: 24px;
        padding-left: 1px;

        &-items {
            @include dGrid(12px, 320px, fill);
            @include mediaMax(768px) {
                @include dGrid(12px, 240px, fill);
            }
        }

        &-item {
            flex: 1 1 calc(100% / 2 - 12px);
        }

        &--title {
            margin-bottom: 8px;
        }
    }

    &__archive {
        max-width: 200px;
    }

    #{$this}__content {
        min-height: auto;
        padding: 0;
        font-size: 15px;
        line-height: 1.42;

        // word-break: break-all;
        color: $DARK_VIOLET;

        &--mt {
            margin-top: 24px;
        }

        img {
            max-width: 100%;
        }

        ol, ul {
            padding-left: 0;
        }

        * + * {
            // margin-top: 5px;

            // here we need to check offsets
            // font-size: 15px;
            // line-height: 21px;
            // margin: 15px 0;
       
        }

        a {
            cursor: pointer;
        }
    }

    &__button {
        width: 30px;
        height: 20px;
        background-color: $UFO_GREEN;
        border-radius: 4px;
        border: 1px solid $UFO_GREEN;
        color: #FFFFFF;
        cursor: pointer;
    }

    &__icon {
        margin-right: 5px;
        display: block;
    }

    .ci-file_blank_outline {
        color: $SILVER;
    }

    .ci-file_blank_fill {
        color: $BRIGHT_CERULEAN;
    }

    .ci-info_circle_outline {
        color: $CORNFLOWER;
        margin-left: 8px;
        margin-right: 0;
    }

    &__files {
        margin-top: 9px;
    }

    &__title {
        display: flex;
        align-items: center;
        column-gap: 8px;
        margin-bottom: 8px;
        font-weight: 500;
        color: $DARK_VIOLET;

        @include mediaMax($tablet) {
            margin-bottom: 16px;
        }


        svg {
            cursor: pointer;
        }
    }

    &__id {
        margin-right: 16px;
    }

    &__archive-icon {
        margin-left: auto;
        cursor: pointer;
    }
}

.drop-block {
    position: relative;
    display: block;
    width: 163px;
    height: 151px;
    padding: 32px 20px;
    text-align: center;
    border-radius: 2px;
    border: 1px dashed $GALLERY;
    cursor: pointer;

    &__input {
        position: absolute;
        inset: 0;
        opacity: 0;
        z-index: -1;
    }

    &__icon {
        margin-bottom: 10px;
    }

    &__text {
        font-size: 10px;
        font-weight: 500;
        color: $SILVER;
    }

    &__title {
        @include mxAuto;

        max-width: 100px;
        margin-bottom: 8px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: $DARK_VIOLET;
    }

    &__input {
        display: none;
    }
}

.block-archive {
    max-width: 750px !important;
    width: 100% !important;

    &__inner {
        padding: 20px 2px 20px 20px;
    }

    &__bottom {
        overflow-y: auto;
        max-height: calc(100vh - 77px);
        padding-left: 5px;
        padding-right: 10px;
    }
}
