@import "./src/styles/abstracts/colors";
@import "./src/styles/abstracts/mixin";

.task-card {
    $this: &;

    @include box-shadow;

    padding: 13px 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
    background-color: $WHITE;
    border-radius: 2px;

    &:hover {
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    }

    &--light {
        background-color: $WHITE;
    }

    &--active {
        border: 2px inset $BRIGHT_CERULEAN;
    }

    &#{$this}--dark {
        $actual: #5C5B45;
        $reoffer: #2B343B;
        $checkTest: #2A4C3B;
        $checkProd: #254C7A;

        background-color: $ARSENIC;

        &#{$this}--101 {
            background-color: $ONYX;
        }

        #{$this}__btn {
            color: $WHITE;
        }

        #{$this}__id {
            color: #D5D6D7;
        }

        #{$this}__title,
        #{$this}__counter,
        #{$this}__info,
        #{$this}__headline {
            color: $IRON;
        }

        #{$this}__bottom-text,
        #{$this}__text {
            color: $GUNSMOKE;
        }

        #{$this}__container {
            background-color: $ONYX;
        }

        #{$this}__line {
            background-color: $GUNSMOKE;
        }

        &#{$this}--isActualCo {
            background-color: $actual;
        }

        &#{$this}--isReofferCo {
            background-color: $reoffer;
        }

        &#{$this}--isCheckTestCo {
            background-color: $checkTest;
        }

        &#{$this}--isCheckProdCo {
            background-color: $checkProd;
        }

        svg:not(#{$this}__icon) path {
            fill: $GUNSMOKE;
        }

        #{$this}__icon path {
            stroke: $GUNSMOKE;
        }

        #{$this}__all-co {
            color: #fff;
        }

        #{$this}__all-co-skeceton {
            background-color: $ONYX;
        }
    }

    &#{$this}__col-head--dark {
        background-color: $ONYX;
    }

    $actual: #FDFAD5;
    $reoffer: #F0F0F0;
    $checkTest: #DAFFDC;
    $checkProd: #DDEDFF;
    $reject: #FFF0F0;

    &--custom {
        text-align: center;
        padding: 0;
        overflow: hidden;

        &:hover {
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

            .project-card__headline {
                color: $BLUE_DE_FRANCE;
            }
        }
    }

    &--101 {
        background-color: $WHITE_SMOKEY;
    }

    &--isActualCo {
        background-color: $actual;
    }

    &--isReofferCo {
        background-color: $reoffer;
    }

    &--isCheckTestCo {
        background-color: $checkTest;
    }

    &--isCheckProdCo {
        background-color: $checkProd;
    }

    &--isRejectCo {
        background-color: $reject;
    }

    &--negayno {
        animation: pulse 1s infinite linear;

        @keyframes pulse {
            50% {
                background-color: #fff0f0;
            }
        }
    }

    &__content {
        border: none;
        width: 100%;
        background-color: transparent;
    }

    &__block {
        @include dFlex(4px, row, center, center);
    }

    &__flex {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    &__headline {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: $SCARPA_FLOW;
        display: flex;
        align-items: end;

        &--custom {
            justify-content: center;
            color: $BLACK_PEARL;
            font-weight: 400;
        }
    }

    &__incident {
        // --size: 18px;
        // position: relative;
        // width: var(--size);
        // height: var(--size);
        display: flex;
        padding: 2px;
        align-items: center;
        justify-content: center;
        box-sizing: content-box;
        cursor: pointer;

        // background-color: #FF0000;
        border: 2px solid #FF0000;
        border-radius: 50%;
        background-clip: content-box;
        animation: iconPriorityAnimate 1s infinite;

        &--done {
            margin-left: auto;
        }

        @keyframes iconPriorityAnimate {
            0% {
                border-color: #FF0000;
            }

            100% {
                border-color: #C1C1C1;
            }
        }

        &::after {
            content: '';

            // position: absolute;
            margin: auto;
            width: 10px;
            height: 10px;

            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
            animation: iconPriorityAnimate1 1s infinite;
            border-radius: 50%;

            @keyframes iconPriorityAnimate1 {
                0% {
                    background-color: #FF0000;
                }

                100% {
                    background-color: #C1C1C1;
                }
            }
        }
    }

    &__all-co {
        position: absolute;
        bottom: 10px;
        right: 16px;

        &-skeceton {
            margin-bottom: 0;

            &:first-of-type {
                margin-right: auto;
            }
        }
    }

    &__title {
        @include textOverflow(4);

        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: $BLACK_PEARL;
        margin: 4px 0 16px;
        text-align: left;
        word-break: break-word;
    }

    &__title--done {
        color: $BRIGHT_CERULEAN;
    }

    &__type {
        &:after {
            display: block;
            font-family: "coolicons";
            font-size: 17px;
        }

        &--incident:after {
            content: "\e91b";
            color: $RED;
        }
    }

    &__pulse-icon {
        @mixin animation {
            animation: iconPriorityAnimate1 1s infinite;

            @keyframes iconPriorityAnimate1 {
                100% {
                    fill: #C1C1C1;
                }
            }
        }

        & + & {
            margin-left: 8px;
        }

        &--pause {
            margin-left: auto;

            path {
                @include animation;
            }
        }

        &--pulse {
            circle {
                @include animation;
            }
        }
    }

    &__details {
        margin-bottom: 16px;
    }

    &__attached-files {
        font-size: 16px;
        line-height: 22px;
        display: flex;
        align-items: center;
        margin-right: 16px;

        &-icon {
            width: 14px;
            margin-right: 4px;
        }
    }

    &__overdue {
        font-size: 16px;
        line-height: 22px;
        color: $RED;
        display: flex;
        align-items: center;

        &-icon {
            width: 14px;
            fill: $RED;
        }
    }

    &__info {
        font-size: 12px;
        line-height: 16px;
        color: $BLACK_PEARL;
        display: flex;
        align-items: center;

        &--staff > span {
            margin-right: 4px;
        }
    }

    &__id {
        font-size: 13px;
        line-height: 18px;
        color: $SCARPA_FLOW;
        margin-right: 6px;
    }

    &__project {
        @include textOverflow(2);

        word-break: break-all;
        font-size: 14px;
        line-height: 19px;
        color: $BRIGHT_CERULEAN;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        text-align: left;

        &:before {
            // content: '';
            flex-shrink: 0;
            display: block;
            height: 10px;
            width: 10px;
            margin-right: 4px;
            background: url("./../../../media/images/project-point.svg") no-repeat;
        }
    }

    &__text {
        font-size: 12px;
        line-height: 16px;
        color: $SCARPA_FLOW;
        text-align: left;
    }

    &__progress {
        background-color: $CORNFLOWER;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    &__line {
        width: 100%;
        height: 4px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $GALLERY;
    }

    &__progress,
    &__line {
        border-radius: 2px;
    }

    &__deadline {
        box-sizing: border-box;
        margin-top: 8px;
        padding: .25em .4em;
        background-color: #ec536c;
        font-weight: 500;
        color: #fff;
        font-size: 85%;
        border-radius: 0.25rem;

        &--overdue {
            border: 2px solid #000;
        }
    }

    &__counter {
        border: 1px solid $GALLERY;
        border-radius: 5px;
        width: 30px;
        height: 20px;
        font-size: 14px;
        line-height: 1;
        color: $SCARPA_FLOW;
        text-align: center;
        padding: 2px;
        display: block;
        margin-left: 8px;

        &--bottom {
            margin-left: 0;
            margin-right: 16px;
            background-color: #ec536c;
            border-color: #ec536c;
            color: $WHITE;
        }
    }

    &__sales-manager {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;

        // column-gap: 50%;

        &-left {
            display: flex;
            flex-direction: column;
            align-items: baseline;
        }

        &-right {}

        &-title {
            color: #D5D6D7;
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 4px;
        }
    }

    &__priority {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
    }

    &__price {
        width: max-content;
        padding: 2px 4px;
        border: 1px solid;
        border-radius: 5px;
        font-size: 10px;

        &--confirmed {
            color: #44b529;
        }

        &--overdue {
            color: #f0b139;
        }

        &--requested {
            color: #c1c1c1;
        }

        &:not(:last-of-type) {
            margin-bottom: 8px;
        }

        &:last-of-type {
            // margin-bottom: 12px;
       
        }
    }

    #{$this}__level {
        width: 44px;
        background-color: $SILVER;
        height: 2px;
        border-radius: 2px;
        margin-right: 2px;

        &--custom {
            &:nth-child(1) {
                background-color: $TULIP_TREE;
            }

            &:nth-child(2) {
                background-color: $CADMIUM_ORANGE;
            }

            &:nth-child(3) {
                background-color: $CADMIUM_ORANGE_DARK;
            }

            &:nth-child(4) {
                background-color: $DEEP_CARROT_ORANGE;
            }
        }
    }

    &__button-draft {
        width: 100%;
        margin-left: 2px;
    }

    &__button-draft:hover,
    &__button-draft:active {
        background-color: $DARK_GREY;
        border-color: initial;
    }
}
