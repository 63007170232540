@import "./variables";

@mixin respond-to($media) {
    @if $media == mobile-portrait {
        @media only screen and (max-width: $mobile-portrait - 1) {
            @content;
        }
    }

    @if $media == mobile-landscape {
        @media only screen and (max-width: $mobile-landscape - 1) {
            @content;
        }
    }
    @else if $media == tablet {
        @media only screen and (max-width: $tablet - 1) {
            @content;
        }
    }
    @else if $media == middleLaptop {
        @media only screen and (max-width: $laptop - 1) {
            @content;
        }
    }
    @else if $media == laptop {
        @media only screen and (max-width: $desktop - 1) {
            @content;
        }
    }
    @else if $media == desktop {
        @media only screen and (max-width: $large-desktop - 1) {
            @content;
        }
    }
    @else if $media == largeDesktop {
        @media only screen and (min-width: $large-desktop) {
            @content;
        }
    }
}

@mixin custom-scroll-bar($bg-color: var(--scroll-thumb-color), $thumb-color: transparent, $margin-bottom: 0px, $thumb-hover-color: $DARK_GREY) {
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
        margin-left: -4px;
        background-color: $thumb-color;
    }

    &::-webkit-scrollbar-track {
        background-color: $thumb-color;
        margin-bottom: $margin-bottom;
    }

    &::-webkit-scrollbar-thumb {
        // background-color: $bg-color;
        background-color: $bg-color;
        border-radius: 3px;

        &:hover,
        &:active {
            background-color: $thumb-hover-color;
        }
    }
}

@mixin box-shadow($posX: 0px, $posY: 1px, $pow: 4px, $blur: 0px, $color: $SOFT_BLACK) {
    box-shadow: $posX $posY $pow $blur $color;
}

@mixin imageCenter($width: 100%, $height: 100%) {
    display: block;
    width: $width;
    height: $height;
    object-fit: cover;
    object-position: center;
}

@mixin dFlex($gap: 0px, $direction: row, $justify: flex-start, $align: flex-start, $flex: flex) {
    display: $flex;
    gap: $gap;
    flex-direction: $direction;
    justify-content: $justify;
    align-items: $align;
}

@mixin dGrid($gap: 0, $min: 200px, $fit: fit, $inline: false) {
    @if $inline {
        display: inline-grid;
    }
    @else {
        display: grid;
    }

    grid-template-columns: repeat(if($fit == fit, auto-fit, auto-fill), minmax($min, 1fr));
    gap: $gap;
}

@mixin baseFont {}

@mixin headingFont($max, $min, $l: 1.16, $ls: normal, $w: 600, $ff: $headingFont) {
    font: $w (clamp(#{$min + "px"}, 7vw, #{$max + "px"}) / $l) $ff;
    letter-spacing: $ls;
}

@mixin mxAuto {
    margin-left: auto;
    margin-right: auto;
}

@mixin clamp($prop, $min: 5vw, $res: 0, $max: 0) {
    #{$prop}: clamp($min, $res, $max);
}

@mixin posCenter {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin textOverflow($lines: 1) {
    overflow: hidden;
    display: -webkit-box;
    box-orient: vertical;
    text-overflow: ellipsis;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}

@mixin mediaMin($breakpoint) {
    @media screen and (min-width: $breakpoint) {
        @content;
    }
}

@mixin mediaMax($breakpoint) {
    @media screen and (max-width: $breakpoint) {
        @content;
    }
}

@mixin customRange($tsize: 14px, $bg: $LIGHT_BLUE, $thumb: $LIGHT_BLUE, $thumb-hover: $COOL_GREY, $border-color: #fff, $s-bg: $SOFT_GRAY) {
    --size: 6px;
    --bg-position: 10%;
    width: 100%;
    height: var(--size);
    margin: 6px 0;
    -webkit-appearance: none;
    background-color: transparent;

    &:focus {
        outline: none;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: var(--size);
        cursor: pointer;
        border-radius: 8px;
        background: linear-gradient(to right, $bg var(--bg-position), $s-bg 0%);
    }

    &::-webkit-slider-thumb {
        width: $tsize;
        height: $tsize;
        margin-top: calc(-#{$tsize} * 0.3);
        border-radius: 50%;
        background: $thumb;
        cursor: pointer;
        border: 1px solid $border-color;
        -webkit-appearance: none;

        &:hover {
            background-color: $thumb-hover;
        }
    }

    &::-moz-range-track {
        width: 100%;
        height: calc(var(--size));
        cursor: pointer;
        border-radius: 8px;

        // border: 0.2px solid $border-color;
        background: linear-gradient(to right, $bg var(--bg-position), $SOFT_GRAY 0%);
    }

    &::-moz-range-thumb {
        position: relative;
        height: $tsize / 1.3;
        width: $tsize / 1.3;
        border-radius: 50%;
        margin-top: calc(-#{$tsize} * 0.3);
        border: 1px solid $border-color;
        background: $thumb;
        cursor: pointer;

        &:hover {
            background-color: $thumb-hover;
        }
    }

    &::-ms-track {
        width: 100%;
        height: var(--size);
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        border-width: 16px 0;
        color: transparent;
    }

    &::-ms-fill-lower,
    &::-ms-fill-upper {
        background: $LIGHT_BLUE;
        border: 0.2px solid $border-color;
        border-radius: 2.6px;
    }

    &::-ms-thumb {
        border: 1px solid $border-color;
        height: $tsize;
        width: $tsize;
        border-radius: 50%;
        background: $thumb;
        cursor: pointer;

        &:hover {
            background-color: $thumb-hover;
        }
    }

    &:focus::-webkit-slider-runnable-track,
    &:focus::-ms-fill-lower,
    &:focus::-ms-fill-upper {
        background: $COOL_GREY;
    }
}
