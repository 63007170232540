@import '../../../styles/abstracts/mixin';
@import '../../../styles/abstracts/colors';

.dot {
    --size: 8px;
    --color: #{$LIGHT_GREY};

    @include dFlex(0, row, center, center);

    width: var(--size);
    height: var(--size);

    font: 500 10px/1.5 $baseFont;
    color: $WHITE;
    border-radius: 50%;
    background-color: var(--color);

    // sizes
    &--xs {
        --size: 4px;
    }

    &--sm {
        --size: 6px;
    }

    &--md {
        --size: 8px;
    }

    &--lg {
        --size: 10px;
    }

    &--xl {
        --size: 13px;
    }

    &--xxl {
        --size: 17px;
    }

    // variants
    &--danger {
        --color: #{$TULIP_TREE};
    }

    &--warning {
        --color: #{$CARNATION};
    }
}
