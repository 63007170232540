@import "../../../styles/abstracts/mixin.scss";

.info {
    $this: &;
    @include dFlex(4px 8px, row, flex-start, center);

    padding: 2px 0;  

    &--vertical {
        flex-direction: column;

        #{$this}__texts {
            align-items: center;
        }
    }

    &__picture {
        flex-shrink: 0;
    }

    &__texts {
        @include dFlex(0, column, center);

        flex: 1;
    }
}
