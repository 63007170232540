@import "./../../styles/abstracts/_colors";
@import "./src/styles/abstracts/_mixin";

.overlay {
    $this: &;

    z-index: 3;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    padding-left: 50px;
    transition: background-color .2s linear;
    height: calc(var(--vh, 1vh) * 100);

    &--mobile {
        padding-left: 20px;
    }

    &--is-active {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

.modal {
    $this: &;

    z-index: 4;
    position: relative;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    width: 100%;
    width: max-content;
    transform: translateX(100%);
    transition: transform .1s linear;
    display: flex;
    flex-direction: column;

    &__body {
        height: 100%;
    }

    &--is-active {
        transform: translateX(0);
    }

    &--light {
        background-color: $WHITE;

        #{$this}__header {
            background-color: $ATHENS_GRAY;
            color: $SCARPA_FLOW;
        }

        #{$this}__body {
            color: $SILVER;

            // width: max-content;
       
        }

        #{$this}__close-btn {
            color: $SCARPA_FLOW;
            border-color: $GALLERY;
            background-color: $WHITE;
        }
    }

    &--dark {
        background-color: $ARSENIC;

        #{$this}__header {
            background-color: $ONYX;
        }

        #{$this}__title,
        #{$this}__body {
            color: $IRON;
        }

        #{$this}__close-btn {
            color: $GUNSMOKE;
            border-color: $SHARK;
            background-color: $ARSENIC;
        }

        .form__input,
        .form__textarea {
            background-color: $ONYX;
            border-color: $ONYX;
            color: $IRON;
        }
    }

    &--sm {
        max-width: 375px;
        width: 100%;

        #{$this}__body {
            width: 100%;
        }
    }

    &--md {
        max-width: 100%;

        // here is for test
        @include mediaMax(992px) {
            width: 100%;
        }


        #{$this}__body {
            width: 100%;
        }
    }

    &--lg {
        max-width: 1500px;
        width: 100%;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mediaMin($tablet) {
            padding: 4px 6px 4px 24px;
        }


        @include mediaMax($tablet) {
            padding: 10px 4px 10px 24px;
        }


        &--dark {
            background-color: $ONYX !important;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
    }

    &__close-btn {
        width: 34px;
        height: 34px;
        cursor: pointer;
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        font-size: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__participants {
        padding: 36px 24px;
    }

    &__side-button {
        position: absolute;
        z-index: 999;
        width: 42px;
        height: 34px;
        margin-top: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateX(-100%);
        border-radius: 2px 0px 0px 2px;
        background: $ATHENS_GRAY;
        cursor: pointer;

        // svg {
        //     width: 18px;
        //     height: 18px;

        //     path {
        //         fill: $DARK_VIOLET;
        //     }
        // }

        &--dark {
            background-color: $ONYX;
            color: $WHITE;
        }
    }
}

// @include respond-to(mobile-portrait) {
//     .modal {
//         width: calc(100% - 32px);
//     }
// }
