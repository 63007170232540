@import "../../styles/abstracts/colors";
@import "../../styles/abstracts/mixin";

.commercial-offer {
    $this: &;

    width: 100%;
    padding: 25px 24px 0 24px;
    border-radius: 6px;
    background-color: $DARK_JUNGLE_GREEN;
    color: $WHITE;

    // & + & {
    //     margin-top: 16px;
    // }

    &__priority {
        display: flex;
        align-items: center;
        gap: 4px;

        &-rating {
            width: 10px;
            aspect-ratio: 1;
            border-radius: 50%;
            background-color: $SILVER;

            &--1 {
                background-color: $TULIP_TREE;
            }

            &--2 {
                background-color: $CADMIUM_ORANGE;
            }

            &--3 {
                background-color: $CADMIUM_ORANGE_DARK;
            }

            &--4 {
                background-color: $DEEP_CARROT_ORANGE;
            }
        }
    }

    &__header {
        position: relative;
        padding: 9px 16px 9px 16px;
        margin-bottom: 24px;
        background-color: #282934;

        @include mediaMax(768px) {
            font-size: 14px;
        }


        &--client {
            padding-right: 60px;
        }
    }

    &__footer,
    &__body {
        padding-bottom: 24px;

        @include mediaMax(768px) {
            padding-bottom: 12px;
        }
    }

    &__man {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    &__item {
        position: relative;

        // z-index: 9;
        padding: 10px 24px 11px;
        font-size: 14px;
        border-radius: 2px;
        background: #323141;

        @include mediaMax(768px) {
            padding: 8px 18px;
            font-size: 12px;
        }


        &:not(:last-child) {
            margin-bottom: 16px;
        }

        &:not(&--second):not(&--short):not(&--transparent) {
            border: 1px solid #4F4F4F;
        }

        &--short,
        &--second {
            padding: 9px 16px;
        }

        &--short {
            max-width: fit-content;
            margin-left: auto;

            &:not(:last-child) {
                margin-top: 16px;
            }
        }

        &--top {
            margin-top: 20px;
        }

        &#{$this}__item--transparent {
            display: flex;
            justify-content: flex-end;
            column-gap: 16px;
            padding: 0;
            border: none;
            background-color: transparent;
        }

        &#{$this}__item--textarea-wrapper {
            padding: 16px;
        }

        &--df {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }

    &__title {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 500;

        @include mediaMax(768px) {
            font-size: 12px;
        }
    }

    &__developer-toggler-wrapper {
        z-index: 0;
    }

    &__input {
        min-height: 69px;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        color: $WHITE;
        cursor: text;

        &::placeholder {
            color: $SILVER;
        }

        &--textarea {
            padding: 10px;
            background-color: #fff;
            color: #1E1F28;
            cursor: text;
            border-radius: 3px;
        }

        &--short {
            max-width: 100px;
            margin: 0 15px 0 10px;
            min-height: auto;
        }
    }

    &__select {
        border: none;

        .custom-select__control {
            background-color: #fff;
        }
    }

    &__developer-toggler {
        display: none;
    }

    &__label {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 14px;
    }

    &__buttons {
        max-width: max-content;
        display: flex;
        margin-top: 24px;
        margin-left: auto;
        column-gap: 16px;
    }

    &__button {
        padding: 8px 10px;
        background-color: #282934;
        color: $SILVER;
        transition: background-color .1s linear, color .1s linear;

        &:hover {
            background-color: #3A3A4A;
        }

        &--success {
            background-color: $UFO_GREEN;
            color: $WHITE;

            &:hover {
                background-color: $CRAYOLA;
            }
        }

        &--success-small {
            margin-top: 8px;
            padding: 5px;
            height: auto;
            font-size: 12px;
        }

        &--danger {
            background-color: #BE0000;
            color: $WHITE;

            &:hover {
                background-color: #FF0000;
            }
        }
    }

    &__costs {
        display: flex;
        column-gap: 24px;
    }

    &__costs-left,
    &__costs-right {
        display: flex;
        align-items: baseline;
    }

    &__cost-old {
        color: $SILVER;
        text-decoration: line-through;
        font-size: 10px;
    }

    &__cost-sale,
    &__costs-left {
        font-size: 16px;
        font-weight: 600;

        @include mediaMax(768px) {
            font-size: 12px;
        }
    }

    &__cost-sale {
        margin-left: 8px;
        color: $CARNATION;

        &--accent {
            color: $TULIP_TREE;
        }
    }

    #{$this}__range {
        flex-direction: column-reverse;

        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        &-text {
            display: block;
            font-size: 14px;
            font-weight: 600;

            @include mediaMax(768px) {
                font-size: 10px;
            }


            &--active {
                position: initial;
                color: $WHITE;
            }

            &:nth-of-type(2) {
                margin-right: 18px;
            }
        }

        &-input {
            @include customRange(24px);

            --size: 10px;
            border-radius: 8px;
            border: 1px solid #FFF;
            box-sizing: content-box;
            background-color: transparent;

            &::-moz-range-track {
                background: linear-gradient(90deg, #F09819 0%, #FF512F var(--bg-position), #fff 0%);
            }

            &::-webkit-slider-runnable-track {
                background: linear-gradient(90deg, #F09819 0%, #FF512F var(--bg-position), #fff 0%);
            }

            &::-moz-range-thumb {
                background: radial-gradient(circle, #F09819 40%, #fff 0%);
            }

            &::-webkit-slider-thumb {
                background: radial-gradient(circle, #F09819 40%, #fff 0%);
            }
        }
    }

    @include mediaMax(768px) {
        &__costs-left {}
    }
}

.commercial-offer-check {
    $this: &;

    order: -1;
    flex: 0 1 calc((100% - 8px) / 2);
    max-width: 349px;
    min-width: 220px;
    width: 100%;
    padding: 20px 20px 0 20px;
    background-color: #EEF3FE;
    border-radius: 2px;
    color: $DARK_VIOLET;

    &:nth-of-type(1n + 1) {
        margin-right: -16px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 16px;
    }

    &__body {
        border-bottom: 1px solid #E0E7F6;
    }

    &__body,
    &__footer {
        padding-bottom: 24px;
    }

    &__footer {
        padding-top: 8px;
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 500;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }
    }

    &__item-ttl {
        font-size: 20px;
        color: $DARK_VIOLET;

        &--md {
            font-size: 24px;
        }
    }

    &__item-text {
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.06);
        padding: 10px 24px 11px;
        font-size: 14px;
        border-radius: 2px;

        &::before {
            margin-left: 10px !important;
        }
    }

    &__button {
        height: auto;
        padding: 5px 6px 5px;
        border-radius: 5px;
        pointer-events: none;
        cursor: default;
    }

    &__total {
        font-size: 18px;
        font-weight: 700;
    }

    &__logo {
        width: 108px;
        margin-bottom: 5px;
    }

    &__title {
        flex-basis: 100%;
        text-align: center;
    }

    &__modal-inner {
        width: 750px;
    }

    &__modal-body {
        padding: 20px;
    }

    &__content {
        overflow-y: initial;
        display: flex;
        flex-direction: column;
        max-height: 90vh;
        padding-left: 2px;
        padding-right: 2px;
        margin-left: -2px;
        overflow-y: auto;

        ol {
            padding-left: 0 !important;
        }
    }

    &__item-title {
        font-size: 16px;
        font-weight: 500;
        color: $DARK_VIOLET;
        margin-bottom: 8px;
    }

    &__show-details {
        color: #707070;
        cursor: pointer;
    }

    &__priority {
        display: flex;
        align-items: center;
    }

    &__priority-color {
        display: block;
        width: 4px;
        height: 4px;
        margin-right: 6px;
        border-radius: 50%;
        transform: translateY(-2px);
    }
}

.checks {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
}

.task-assessment {
    $this: &;

    margin-top: 15px;
    background-color: #1E1F28 !important;
    border-radius: 6px;
    color: #fff;
    padding: 10px;
    margin-bottom: 10px;

    td {
        padding: 10px;

        &:first-of-type {
            width: 50%;
        }

        @include mediaMax(768px) {
            padding: 5px;
        }
    }

    td, th {
        font-size: 13px;
    }

    &__content {
        & + & {
            margin-top: 25px;
        }
    }

    &__error {
        font-size: 13px;
        color: red;

        &--textarea {
            margin-top: -10px;
        }
    }

    &__table {
        width: 100%;

        @include mediaMax(768px) {
            table-layout: fixed;
        }
    }

    &__table-heading {
        text-align-last: left;
    }

    &__table-thin {
        display: block;
        margin-left: auto;
        max-width: 90px;

        // background-color: red;
   
    }

    &__item,
    &__table-wrapper,
    &__title,
    &__sub-title {
        background: #282934;
        border-radius: 2px;
        padding: 9px 16px 9px 16px;
        margin-bottom: 10px;
    }

    &__item {}

    &__title {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
        position: relative;
    }

    &__sub-title {
        &--sm {
            font-size: 13px;
            white-space: break-spaces;
        }

        h4 {
            margin-bottom: 10px;
        }
    }

    &__table-wrapper {
        margin-top: 10px;
    }

    &__input {
        display: block;
        margin-bottom: 10px;
        width: 100%;
        height: calc(2.25rem + 2px);
        padding: .375rem .75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        font-size: 13px;
        height: auto;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
        cursor: text !important;

        &:focus {
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }

    &__label {
        margin-bottom: 4px;
        display: block;
        font-size: 13px;
    }

    &__through {
        text-decoration: line-through;
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: flex-end;
        margin-top: 15px;
        margin-left: auto;
    }

    &__delete-btn {
        margin-left: 15px;
        background-color: #F1556D;
        border: none;
        color: #fff;
        transition: background-color .1s linear, color .1s linear;

        &:hover {
            background-color: #b82e43;
        }
    }

    &__check-btn {
        svg path {
            fill: #fff;
        }
    }

    &__text-right {
        padding-right: 20px !important;
        text-align: right;
    }

    &__text--small {
        font-size: 13px;
    }

    &__btn {
        margin-top: 15px;

        &--color-white {
            color: #fff;
        }

        &--finish {
            background-color: #282934;
            border-color: #282934;
        }
    }
}

.commercial-offer-developer {
    tr > * {
        flex-basis: auto;
    }

    &__item {
        padding: 10px 0;

        &:not(:last-child) {
            margin-bottom: 30px;
            position: relative;

            &::after {
                content: '';
                position: absolute;
                bottom: -20px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #4F4F4F;
            }
        }
    }

    &__staff-header {
        display: flex;

        // justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;

        &-item {
            flex: 1;
            max-width: calc(100% / 2 - 28px);

            &:first-of-type {
                margin-right: 15px;
            }

            &--text {
                flex-basis: 45%;
            }

            &--price {
                min-width: 145px;
            }
        }
    }

    &__staff {
        width: 100%;
    }

    &__staff-footer--coll {
        // margin-left: auto;
        margin-right: 20px;

        &-right {
            // min-width: 182px;
            text-align: right;
        }
    }

    &__staff-footer--empty {
        min-width: 145px;
    }

    &__staff-calculation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #4F4F4F;

        &:last-of-type {
            margin-bottom: 30px;
        }

        &--coll {
            flex: 1;

            // text-align: center;
            text-align: left !important;

            &:first-of-type {
                margin-right: 15px;
            }

            &:last-of-type {
                text-align: right;
            }

            &-text {
                flex-basis: 45%;
            }

            &-hour {
                // text-align: center !important;
           
            }
        }

        &--short {
            display: inline-flex;
            align-items: center;
            gap: 7px;
        }

        &--price {
            min-width: 145px;
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 12px;
    }

    &__staff {
        table-layout: fixed;
        padding-left: 10px;

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    &__staff-name {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 12px;
    }

    &__togler-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        &:not(:last-of-type) {
            margin-bottom: 12px;
        }
    }

    &__developer-toggler {
        display: block;

        // margin-top: 16px;
        color: $WHITE;
        cursor: pointer;
    }

    &__developer-button,
    &__button {
        margin-top: 12px;
    }

    &__label {
        font-size: 12px;
        font-weight: 500;
    }
}
