@import "_mixin";
@import "_colors";

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &--row {
        flex-direction: row;
        margin-bottom: 24px;
        align-items: center;
    }

    &__create-task-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__title {
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        color: $BLACK_PEARL;
        text-align: center;
        margin-bottom: 16px;

        &--restore {
            height: auto;
        }

        &--sm {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &__item {
        margin-right: 16px;
        width: 220px;
    }

    &__fieldset {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 16px;
        position: relative;

        &:last-child {
            margin-bottom: 0;
        }

        &--margin {
            margin-bottom: 0;
        }
    }

    &__label {
        font-size: 16px;
        line-height: 22px;
        color: $SILVER;
        margin-bottom: 4px;
        text-align: left;

        &--custom {
            color: $BLACK_PEARL;
        }

        &--login {
            font-size: 14px;
        }

        &--button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: fit-content;
        }
    }

    &__input {
        &--login::placeholder,
        &--login {
            font-size: 14px;
        }
    }

    &__input,
    &__select,
    &__textarea {
        border: 1px solid $GALLERY;
        border-radius: 2px;
        padding: 8px 10px;
        outline-style: none;

        &::placeholder {
            color: $GUNSMOKE;
        }

        &--participants {
            width: 100%;
        }
    }

    &__radio {
        margin-right: 10px;
    }

    &__textarea {
        height: 120px;
    }

    &__error {
        font-size: 12px;
        line-height: 16px;
        color: $RED;
        margin-top: 8px;

        &--new-member {
            position: absolute;
            top: 10px;
        }
    }

    &__extra-text {
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        position: absolute;
        right: 10px;
        top: 35px;
    }

    &__btn {
        border-radius: 2px;
        background-color: $BLUE_DE_FRANCE;
        border: none;
        color: $WHITE;
        display: block;
        padding: 8px 10px;
        margin-top: 16px;
        cursor: pointer;

        &:hover,
        &:active {
            background-color: $BLUE_DE_FRANCE_DARK;
        }

        &--margin {
            margin-top: 0;
        }
    }
}

@include respond-to(mobile-landscape) {
    .form {
        &__title {
            font-size: 28px;
        }
    }
}
