/* noto-sans-regular - latin_cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('./../../media/fonts/noto-sans-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./../../media/fonts/noto-sans-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-500 - latin_cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: local(''),
  url('./../../media/fonts/noto-sans-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./../../media/fonts/noto-sans-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* noto-sans-600 - latin_cyrillic-ext */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  src: local(''),
  url('./../../media/fonts/noto-sans-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./../../media/fonts/noto-sans-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
