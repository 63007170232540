@import "../../styles/abstracts/colors";

.drop-zone {
    position: absolute;
    inset: 0;
    height: 100vh;
    right: 40px;
    z-index: 1;
    // z-index: 99999;

    &__inner {
        // z-index: 1;
        position: absolute;
        inset: 0;
        right: -40px;
        background-color: $BRIGHT_CERULEAN;
        border-radius: 2px;

        &::after {
            content: '';
            z-index: -1;
            pointer-events: none;
            position: absolute;
            inset: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            border: 1px dashed;
            color: $WHITE;
            font-size: 32px;
        }

        svg {
            margin-bottom: 4px;

            path {
                fill: currentColor;
            }
        }
    }
}
