@import "./src/styles/abstracts/_colors";
@import "./src/styles/abstracts/_mixin";

.project-card {
    position: relative;

    // flex: 1 1 calc(25% - 15px);
    min-height: 138px;
    min-width: 200px;
    border-radius: 2px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;

    &:hover {
        box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
    }

    // &:nth-child(4n) {
    //     margin-right: 0;
    // }

    &--light {
        background-color: $WHITE;

        .project-card__container {
            background-color: $WHITE;
        }
    }

    &--dark {
        background-color: $ONYX;

        .project-card__title,
        .project-card__headline {
            color: $IRON;
        }

        .project-card__container {
            background-color: $ONYX;
        }
    }

    &--custom {
        display: flex;
        text-align: center;
        padding: 0;
        overflow: hidden;

        &:hover {
            box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

            .project-card__headline {
                color: $BLUE_DE_FRANCE;
            }
        }
    }

    &__container {
        background-color: $ZIRCON;
        margin: auto;
        border: none;
        display: block;
        width: 100%;
        height: 100%;
        cursor: pointer;
        padding: 16px;
        aspect-ratio: 3 / 1;

        .form__fieldset {
            margin-bottom: 0;
        }

        .form__btn {
            margin-top: 8px;
        }
    }

    &__headline {
        font-size: 16px;
        line-height: 22px;
        color: $BLACK_PEARL;
    }

    &__text {
        font-size: 12px;
        line-height: 16px;
        color: $SILVER;
        margin-top: 4px;
    }

    &__title {
        @include textOverflow(4);

        font-weight: 500;
        font-size: 14px;
        line-height: 19px;
        color: $BLACK_PEARL;
        padding-right: 20px;
        margin-top: 24px;
        text-align: left;
        word-break: break-all;
    }

    &__list {
        display: flex;
        flex-direction: row;
    }

    &__wrapper {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: column;

        // justify-content: space-between;
        height: 100%;
        cursor: pointer;
        width: 100%;
        padding: 16px;
        aspect-ratio: 3 / 1;
    }

    &__item {
        border-radius: 5px;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        color: $WHITE;
        text-align: center;
        padding: 3px 5px;
        margin-right: 1px;
        width: 30px;

        &:last-child {
            margin-right: 0;
        }

        &--new {
            background-color: $SILVER;
        }

        &--pending {
            background-color: $BRIGHT_CERULEAN;
        }

        &--inProcess {
            background-color: $TULIP_TREE;
        }

        &--checking {
            background-color: $UFO_GREEN;
        }
    }

    &__link {
        padding-right: 20px;
        padding-top: 8px;
        margin-top: auto;
        font-size: 12px;
        line-height: 16px;
        color: $SILVER;
        text-align: left;
    }
}

@include respond-to(middleLaptop) {
    // .project-card {
    //     width: calc(33% - 12px);

    //     &:nth-child(3n) {
    //         margin-right: 0;
    //     }

    //     &:nth-child(4n) {
    //         margin-right: 20px;
    //     }
    // }
}


@include respond-to(mobile-landscape) {
    // .project-card {
    //     width: calc(50% - 10px);

    //     &:nth-child(3n) {
    //         margin-right: 20px;
    //     }

    //     &:nth-child(2n),
    //     &:nth-child(4n) {
    //         margin-right: 0;
    //     }
    // }
}


@include respond-to(mobile-portrait) {
    // .project-card {
    //     width: 100%;
    //     margin-right: 0;

    //     &:nth-child(3n) {
    //         margin-right: 0;
    //     }

    //     &__item {
    //         width: 28px;
    //     }
    // }
}
