$WHITE: #FFFFFF;
$SILVER: #C1C1C1;
$LIGHT_GRAY: #D2D2D2;
$IRON: #D5D6D7;
$ATHENS_GRAY: #EFF0F3;
$WHITE_SMOKEY: #F5F5F5;
$GALLERY: #E9EBF0;
$ZIRCON: #F4F8FE;

$UFO_GREEN: #24D37B;
$CRAYOLA: #1AC971;
$SUCCESS_COLOR: #20B96D;
$DEFAULT_COLOR: #A2ABB4;

$CARNATION: #F1556D;
$RED: #FF0000;
$SOFT_RED: #FF512F;

$TULIP_TREE: #F0B139;
$TULIP_TREE_DARK: #db9e2a;
$CADMIUM_ORANGE: #E4862B;
$CADMIUM_ORANGE_DARK: #E6792E;
$DEEP_CARROT_ORANGE: #EA6333;

$BRIGHT_CERULEAN: #1FABF0;
$CORNFLOWER: #5987F8;
$BLUE_DE_FRANCE: #3F8CFF;
$BLUE_DE_FRANCE_DARK: #2B78EB;
$SOFT_BLUE: rgba(63, 140, 255, 0.06);

$BLACK: #000000;
$BLACK_PEARL: #0A1629;
$DARK_JUNGLE_GREEN: #1E1F28;
$SHARK: #1E272E;
$ONYX: #2B343B;
$ARSENIC: #384047;
$SCARPA_FLOW: #544E61;
$GUNSMOKE: #828588;

$COOL_GREY: #8E95A5;
$LIGHT_GREY: #C4C4C4;
$DARK_GREY: #999;
$SOFT_GRAY: #EFEFEF;

$SOFT_BLACK: rgba(0, 0, 0, 0.1);
$VIOLET: rgba(84, 78, 97, 1);
$DARK_VIOLET: #0C182A;
$LIGHT_BLUE: #CAD7E6;
$LIGHT_BLUE_DARK: rgba(171, 184, 199, 1);
$GRAY_SOFT: #FBFBFB;
$WHITE_SMOKEY: #dadce0;
$WHITE_SMOKEY_DARK: #e8e8e9;
$LIGHT_GREEN: #b6fac4;
