@import "../../styles/abstracts/colors.scss";
@import "../../styles/abstracts/variables.scss";
@import "../../styles/abstracts/mixin.scss";

.file-attachment {
    $this: &;

    position: relative;
    display: flex;
    column-gap: 8px;
    padding: 12px;
    border: 1px solid $GALLERY;
    border-radius: 10px;

    // cursor: pointer;

    &--dark {
        background-color: $ONYX;
        border-color: $ONYX;

        #{$this}__title {
            color: $LIGHT_GREY;
        }

        #{$this}__text {
            color: $ATHENS_GRAY;
        }

        #{$this}__progress-bar {
            background: linear-gradient(to right, $CORNFLOWER var(--posX), $WHITE 0) no-repeat 0 0;
        }

        #{$this}__icon {
            path {
                fill: $WHITE;
            }
        }

        #{$this}__delete {
            path {
                fill: $WHITE;
            }
        }

        #{$this}__img {
            box-shadow: 0 0 1px 1px $LIGHT_GREY;
        }
    }

    &__left {
        height: 76px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__left--file {
        width: 56px;

        #{$this}__loader {
            --size: 50px;

            // transform: translate(-5%, 5%);
       
        }
    }

    &__close-icon {
        width: 56px;
        height: 100%;
        transform: translateY(-20%);

        path {
            fill: $DARK_VIOLET;
        }
    }

    &__left--img {
        width: 76px;
        align-items: flex-start;
    }

    &__right {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__text,
    &__title {
        color: $DARK_VIOLET;
    }

    &__title {
        --offset: 3px;
        z-index: 1;
        overflow: initial;
        width: 100%;
        height: max-content;
        margin: 0 0 4px -3px;
        padding: 0 var(--offset) 0 var(--offset);
        font-size: 14px;
        line-height: normal;
        word-break: break-all;
        border-radius: 2px;
        outline: 1px solid transparent;
        white-space: pre-wrap;

        &--cursor-text:hover,
        &--cursor-text:focus {
            outline-color: $CORNFLOWER;
        }
    }

    &__text {
        display: flex;
        align-items: flex-end;
        font-size: 11px;
    }

    &__img {
        @include imageCenter;

        object-fit: contain;
        border-radius: 10px;
        box-shadow: 0 0 1px 1px #DEE2E6;
    }

    &__file svg {
        width: auto;
    }

    &__file svg,
    &__img {
        display: block;
        height: 76px;
    }

    &__img {
        width: 76px;
    }

    &__icon {
        cursor: pointer;

        &--download {
            margin-left: 5px;

            path {
                fill: $GUNSMOKE;
                transition: fill 0.1s ease-in-out;
            }

            &:hover {
                path {
                    fill: $BLUE_DE_FRANCE;
                }
            }
        }

        &--cancel {
            margin-left: 8px;
        }
    }

    #{$this}__loader {
        --size: 60px;
        position: relative;
        margin: auto;
        color: $DARK_VIOLET;
    }

    &__delete {
        margin-left: 5px;
        cursor: pointer;

        path {
            fill: $GUNSMOKE;
            transition: fill 0.1s ease-in-out;
        }

        &:hover {
            path {
                fill: $RED;
            }
        }
    }

    &--loaded:hover {
        #{$this}__delete {
            opacity: 1;
        }
    }

    &__progress {
        width: 100px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 5px;
    }

    &__progress-bar {
        --posX: 0;
        height: 2px;
        width: 70px;
        background: linear-gradient(to right, $CORNFLOWER var(--posX), $SILVER 0) no-repeat 0 0;
        border-radius: 2px;
    }
}
