@import "../../styles/abstracts/colors.scss";
@import "../../styles/abstracts/mixin.scss";

.balance-operations {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px 24px;

    @include mediaMax($laptop) {
        padding: 20px 12px;
    }


    &__chart {
        @include mediaMax(900px) {
            width: 900px;
        }
    }

    &__top {
        display: flex;

        @include mediaMax(900px) {
            flex-wrap: wrap;
        }
    }

    &__bottom {
        overflow-x: auto;
        margin-bottom: 30px;
        padding-bottom: 10px;
        margin-top: 30px;

        @include mediaMax($laptop) {
            margin-top: 10px;
        }
    }

    &__left {
        min-width: 343px;

        @include mediaMax(900px) {
            min-width: 100%;
        }
    }

    &__right {
        position: relative;
        width: 100%;

        @include mediaMax(900px) {
            overflow-x: auto;
            margin-top: 10px;
        }
    }

    &__button {
        width: 100%;
        margin-top: 16px;
        color: $WHITE;
    }
}

.block-total {
    $this: &;

    padding: 20px 24px;
    margin-top: 6px;
    margin-bottom: 6px;
    border-radius: 20px;
    border: 1px solid #EEF2F6;

    &--dark {
        #{$this}__value {
            color: $WHITE;
        }
    }

    &__title {
        margin-bottom: 6px;
        font-weight: 400;
        color: #A2ABB4;
    }

    &__value {
        display: flex;
        align-items: center;
        column-gap: 4px;
        color: $DARK_VIOLET;
        font-size: 24px;
        font-weight: 700;
    }
}

.operations-table {
    $this: &;

    @include mediaMax($laptop) {
        min-width: 690px;
    }


    &--dark {
        #{$this}__row--head {
            background-color: $SHARK;

            #{$this}__cell {
                color: $WHITE;
            }
        }

        #{$this}__show-more {
            color: $WHITE;
        }
    }

    &__body {
        // overflow-y: auto;
        // max-height: 50vh;
   
    }

    &__footer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__show-more {
        border: none;
        background-color: transparent;
        text-decoration: underline transparent;
        font-weight: 600;

        &:hover {
            text-decoration-color: currentColor;
        }

        svg {
            margin-right: 6px;
        }
    }

    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        padding: 12px 24px;

        &--head {
            position: sticky;
            top: 0;
            background-color: $WHITE;
        }

        &:nth-of-type(even) {
            background-color: #FAFBFC;
        }

        &:nth-of-type(odd) {
            // background-color: $WHITE;
       
        }

        &--dark {
            &:nth-of-type(even) {
                background-color: $SHARK;
            }

            #{$this}__cell {
                color: $SILVER;
            }
        }

        @include mediaMax($laptop) {
            padding: 12px;
        }


        &--head {
            #{$this}__cell {
                // font-size: 12px;
                font-weight: 500;
                color: $BLACK_PEARL;
            }
        }

        &--footer {
            margin-top: 12px;
            justify-content: center;
        }
    }

    &__cell {
        flex: 1;
        font-size: 14px;
        line-height: 1.334;
        color: $BLACK_PEARL;
        cursor: text;

        a, a:visited {
            color: inherit;
            text-decoration: none;
        }

        &--success {
            color: $SUCCESS_COLOR;
        }

        &--danger {
            color: $CARNATION;
        }

        &--info {
            color: $CORNFLOWER;
        }

        &--default {
            color: $DEFAULT_COLOR;
        }

        &--date {
            max-width: 134px;
        }

        &--type {
            max-width: 120px;
            margin-left: 43px;
        }

        &--action {
            margin-left: 50px;
        }

        a {
            cursor: pointer;
            text-decoration: underline;
        }

        &--sum-change,
        &--sum-before,
        &--sum-after {
            max-width: 130px;
            margin-left: 100px;
        }

        &--sum-change,
        &--sum-after {
            font-weight: 700;
        }

        &--sum-before {
            font-weight: 500;
        }

        &--sum-after {}

        &--show-more {
            font-size: 14px;
            font-weight: 600;
            color: #000;
        }

        @include mediaMax($laptop) {
            &--date,
            &--type,
            &--action,
            &--sum-change,
            &--sum-before,
            &--sum-after {
                margin-left: 10px;
            }
        }


        &--success {
            color: #20B96D;
        }

        &--warning {
            color: #F1556D;
        }

        &--info {
            color: #5987F8;
        }

        &--default {
            color: #A2ABB4;
        }

        &--base {
            color: $DARK_VIOLET;
        }
    }

    &__button-show-more {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 6px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border: none;
    }
}

.custom-chart {
    $this: &;

    --color: transparent;

    &--dark {
        #{$this}__item,
        .ct-label {
            color: $WHITE !important;
        }
    }

    &__desc {
        display: flex;
        align-items: center;
        column-gap: 13px;
        margin-bottom: 8px;
        margin-left: 83px;
    }

    &__item {
        display: flex;
        align-items: center;
        font-size: 12px;
        color: $BLACK_PEARL;

        @include mediaMax(900px) {
            font-size: 8px;
        }
    }

    &__fake-svg-line {
        position: absolute;
        right: 0;
        height: 100%;
        right: 1%;
    }

    &__icon {
        width: 18px;
        height: 18px;
        flex-shrink: 0;
        margin-right: 8px;
        background-color: var(--color);

        @include mediaMax(900px) {
            width: 10px;
            height: 10px;
        }


        .ct-bar {
            stroke: var(--color) !important;
        }

        // &--1 {
        //     background-color: #20B96D;
        // }

        // &--2 {
        //     background-color: #7AD457;
        // }

        // &--3 {
        //     background-color: #F18055;
        // }
   
    }

    &__color {
        line {
            stroke: var(--color) !important;
        }
    }
}

.chart-wrapper {
    position: relative;
}

.ct-chart {
    position: relative;

    &::after {
        // content: '';
        position: absolute;
        width: 0px;
        top: 0;
        bottom: 34px;
        right: 15px;
        border-left: 1px dashed #A2ABB4;
    }
}

.ct-chart {
    width: 100%;
}

.ct-golden-section {
    max-height: 100%;
}

.hideScroll {
    overflow-y: hidden;
}

// .ct-series.ct-series-a .ct-bar {
//     stroke: #7AD457;
// }

// .ct-series.ct-series-b .ct-bar {
//     stroke: #F18055;
// }

.ct-double-octave:after, .ct-golden-section:after, .ct-major-eleventh:after, .ct-major-second:after, .ct-major-seventh:after, .ct-major-sixth:after, .ct-major-tenth:after, .ct-major-third:after, .ct-major-twelfth:after, .ct-minor-second:after, .ct-minor-seventh:after, .ct-minor-sixth:after, .ct-minor-third:after, .ct-octave:after, .ct-perfect-fifth:after, .ct-perfect-fourth:after, .ct-square:after {
    content: "";
    clear: both;
}

.ct-label {
    fill: rgba(0, 0, 0, 0.4);
    color: rgba(0, 0, 0, 0.4);
    font-size: .75rem;
    line-height: 1;
}

.ct-label:not(.ct-vertical) {
    padding-top: 7px;
    fill: $BLACK_PEARL;
    color: $BLACK_PEARL;
    font-size: 12px;
    font-weight: 500;
}

.ct-vertical {
    // height: 15px !important;
    color: #A2ABB4;
    font-size: 10px;
    font-weight: 400;
}

.ct-chart-bar .ct-label, .ct-chart-line .ct-label {
    display: block;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.ct-chart-donut .ct-label, .ct-chart-pie .ct-label {
    dominant-baseline: central;
}

.ct-label.ct-horizontal.ct-start {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start;
}

.ct-label.ct-horizontal.ct-end {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start;
}

.ct-label.ct-vertical.ct-start {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    -webkit-box-pack: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
    text-align: right;
    text-anchor: end;
}

.ct-label.ct-vertical.ct-end {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    text-anchor: start;
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    text-anchor: start;

    @include mediaMax(900px) {
        font-size: 8px;
        color: $SILVER;
    }
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
    -webkit-box-align: flex-end;
    -webkit-align-items: flex-end;
    -ms-flex-align: flex-end;
    align-items: flex-end;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
    -webkit-box-align: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start;
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: flex-end;
    justify-content: flex-end;
    text-align: right;
    text-anchor: end;

    @include mediaMax(900px) {
        font-size: 8px;
        color: $SILVER;
    }
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: end;
}

.ct-grid {
    stroke: #A2ABB4;
    stroke-width: 1px;
    stroke-dasharray: 4px;
    stroke-dashoffset: 2px;
}

.ct-grid-background {
    fill: none;
}

.ct-point {
    stroke-width: 10px;
    stroke-linecap: round;
}

.ct-line {
    fill: none;
    stroke-width: 4px;
}

.ct-area {
    stroke: none;
    fill-opacity: 0.1;
}

.ct-bar {
    fill: none;
    stroke-width: 30px;

    @include mediaMax(900px) {
        stroke-width: 10px;
    }
}

.ct-slice-donut {
    fill: none;
    stroke-width: 60px;
}

// .ct-series-a .ct-bar, .ct-series-a .ct-line, .ct-series-a .ct-point, .ct-series-a .ct-slice-donut {
//     stroke: #d70206;
// }

// .ct-series-a .ct-area, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-slice-pie {
//     fill: #d70206;
// }

// .ct-series-b .ct-bar, .ct-series-b .ct-line, .ct-series-b .ct-point, .ct-series-b .ct-slice-donut {
//     stroke: #f05b4f;
// }

// .ct-series-b .ct-area, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-slice-pie {
//     fill: #f05b4f;
// }

// .ct-series-c .ct-bar, .ct-series-c .ct-line, .ct-series-c .ct-point, .ct-series-c .ct-slice-donut {
//     stroke: #20B96D;
// }

// .ct-series-c .ct-area, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-slice-pie {
//     fill: #20B96D;
// }

// .ct-series-d .ct-bar, .ct-series-d .ct-line, .ct-series-d .ct-point, .ct-series-d .ct-slice-donut {
//     stroke: #d17905;
// }

// .ct-series-d .ct-area, .ct-series-d .ct-slice-donut-solid, .ct-series-d .ct-slice-pie {
//     fill: #d17905;
// }

// .ct-series-e .ct-bar, .ct-series-e .ct-line, .ct-series-e .ct-point, .ct-series-e .ct-slice-donut {
//     stroke: #453d3f;
// }

// .ct-series-e .ct-area, .ct-series-e .ct-slice-donut-solid, .ct-series-e .ct-slice-pie {
//     fill: #453d3f;
// }

// .ct-series-f .ct-bar, .ct-series-f .ct-line, .ct-series-f .ct-point, .ct-series-f .ct-slice-donut {
//     stroke: #59922b;
// }

// .ct-series-f .ct-area, .ct-series-f .ct-slice-donut-solid, .ct-series-f .ct-slice-pie {
//     fill: #59922b;
// }

// .ct-series-g .ct-bar, .ct-series-g .ct-line, .ct-series-g .ct-point, .ct-series-g .ct-slice-donut {
//     stroke: #0544d3;
// }

// .ct-series-g .ct-area, .ct-series-g .ct-slice-donut-solid, .ct-series-g .ct-slice-pie {
//     fill: #0544d3;
// }

// .ct-series-h .ct-bar, .ct-series-h .ct-line, .ct-series-h .ct-point, .ct-series-h .ct-slice-donut {
//     stroke: #6b0392;
// }

// .ct-series-h .ct-area, .ct-series-h .ct-slice-donut-solid, .ct-series-h .ct-slice-pie {
//     fill: #6b0392;
// }

// .ct-series-i .ct-bar, .ct-series-i .ct-line, .ct-series-i .ct-point, .ct-series-i .ct-slice-donut {
//     stroke: #f05b4f;
// }

// .ct-series-i .ct-area, .ct-series-i .ct-slice-donut-solid, .ct-series-i .ct-slice-pie {
//     fill: #f05b4f;
// }

// .ct-series-j .ct-bar, .ct-series-j .ct-line, .ct-series-j .ct-point, .ct-series-j .ct-slice-donut {
//     stroke: #dda458;
// }

// .ct-series-j .ct-area, .ct-series-j .ct-slice-donut-solid, .ct-series-j .ct-slice-pie {
//     fill: #dda458;
// }

// .ct-series-k .ct-bar, .ct-series-k .ct-line, .ct-series-k .ct-point, .ct-series-k .ct-slice-donut {
//     stroke: #eacf7d;
// }

// .ct-series-k .ct-area, .ct-series-k .ct-slice-donut-solid, .ct-series-k .ct-slice-pie {
//     fill: #eacf7d;
// }

// .ct-series-l .ct-bar, .ct-series-l .ct-line, .ct-series-l .ct-point, .ct-series-l .ct-slice-donut {
//     stroke: #86797d;
// }

// .ct-series-l .ct-area, .ct-series-l .ct-slice-donut-solid, .ct-series-l .ct-slice-pie {
//     fill: #86797d;
// }

// .ct-series-m .ct-bar, .ct-series-m .ct-line, .ct-series-m .ct-point, .ct-series-m .ct-slice-donut {
//     stroke: #b2c326;
// }

// .ct-series-m .ct-area, .ct-series-m .ct-slice-donut-solid, .ct-series-m .ct-slice-pie {
//     fill: #b2c326;
// }

// .ct-series-n .ct-bar, .ct-series-n .ct-line, .ct-series-n .ct-point, .ct-series-n .ct-slice-donut {
//     stroke: #6188e2;
// }

// .ct-series-n .ct-area, .ct-series-n .ct-slice-donut-solid, .ct-series-n .ct-slice-pie {
//     fill: #6188e2;
// }

// .ct-series-o .ct-bar, .ct-series-o .ct-line, .ct-series-o .ct-point, .ct-series-o .ct-slice-donut {
//     stroke: #a748ca;
// }

// .ct-series-o .ct-area, .ct-series-o .ct-slice-donut-solid, .ct-series-o .ct-slice-pie {
//     fill: #a748ca;
// }

.ct-square {
    display: block;
    position: relative;
    width: 100%;
}

.ct-square:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 100%;
}

.ct-square:after {
    display: table;
}

.ct-square > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-minor-second {
    display: block;
    position: relative;
    width: 100%;
}

.ct-minor-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 93.75%;
}

.ct-minor-second:after {
    display: table;
}

.ct-minor-second > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-major-second {
    display: block;
    position: relative;
    width: 100%;
}

.ct-major-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 88.88889%;
}

.ct-major-second:after {
    display: table;
}

.ct-major-second > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-minor-third {
    display: block;
    position: relative;
    width: 100%;
}

.ct-minor-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 83.33333%;
}

.ct-minor-third:after {
    display: table;
}

.ct-minor-third > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-major-third {
    display: block;
    position: relative;
    width: 100%;
}

.ct-major-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 80%;
}

.ct-major-third:after {
    display: table;
}

.ct-major-third > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-perfect-fourth {
    display: block;
    position: relative;
    width: 100%;
}

.ct-perfect-fourth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 75%;
}

.ct-perfect-fourth:after {
    display: table;
}

.ct-perfect-fourth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-perfect-fifth {
    display: block;
    position: relative;
    width: 100%;
}

.ct-perfect-fifth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 66.66667%;
}

.ct-perfect-fifth:after {
    display: table;
}

.ct-perfect-fifth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-minor-sixth {
    display: block;
    position: relative;
    width: 100%;
}

.ct-minor-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 62.5%;
}

.ct-minor-sixth:after {
    display: table;
}

.ct-minor-sixth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-golden-section {
    display: block;
    position: relative;
    width: 100%;
}

.ct-golden-section:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 61.8047%;
}

.ct-golden-section:after {
    display: table;
}

.ct-golden-section > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-major-sixth {
    display: block;
    position: relative;
    width: 100%;
}

.ct-major-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 60%;
}

.ct-major-sixth:after {
    display: table;
}

.ct-major-sixth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-minor-seventh {
    display: block;
    position: relative;
    width: 100%;
}

.ct-minor-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 56.25%;
}

.ct-minor-seventh:after {
    display: table;
}

.ct-minor-seventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-major-seventh {
    display: block;
    position: relative;
    width: 100%;
}

.ct-major-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 53.33333%;
}

.ct-major-seventh:after {
    display: table;
}

.ct-major-seventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-octave {
    display: block;
    position: relative;
    width: 100%;
}

.ct-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 50%;
}

.ct-octave:after {
    display: table;
}

.ct-octave > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-major-tenth {
    display: block;
    position: relative;
    width: 100%;
}

.ct-major-tenth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 40%;
}

.ct-major-tenth:after {
    display: table;
}

.ct-major-tenth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-major-eleventh {
    display: block;
    position: relative;
    width: 100%;
}

.ct-major-eleventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 37.5%;
}

.ct-major-eleventh:after {
    display: table;
}

.ct-major-eleventh > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-major-twelfth {
    display: block;
    position: relative;
    width: 100%;
}

.ct-major-twelfth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 33.33333%;
}

.ct-major-twelfth:after {
    display: table;
}

.ct-major-twelfth > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}

.ct-double-octave {
    display: block;
    position: relative;
    width: 100%;
}

.ct-double-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 25%;
}

.ct-double-octave:after {
    display: table;
}

.ct-double-octave > svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
}
