@import "./src/styles/abstracts/_colors";
@import "./src/styles/abstracts/_mixin";

.header {
    @include box-shadow;

    $this: &;

    z-index: 2;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--header-height);
    padding: 23px 40px;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: -10px 0px 63px rgba(0, 0, 0, 0.08);
    backdrop-filter: blur(20px);
    border-bottom-width: 1px;
    border-bottom-style: solid;

    &--light {
        background-color: rgba(255, 255, 255, 0.1);
        border-bottom-color: $GALLERY;
    }

    &--dark {
        background-color: $ONYX;
        border-bottom-color: $SHARK;
        color: $IRON;

        #{$this}__notification-btn {
            color: $SILVER;
        }
    }

    &__logo {
        @include mediaMax(560px) {
            width: 152px;
        }
    }

    &__list {
        display: flex;
        align-items: center;
    }

    &__item {
        margin-right: 30px;
        position: relative;

        &:last-child {
            margin-right: 0;
        }

        &--balance {
            display: flex;

            > div {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        @include mediaMax($tablet) {
            margin-right: 20px;
        }


        &--icon-balance * {
            fill: currentColor;
        }
    }

    &__notification-btn {
        position: relative;
        font-size: 24px;
        color: $VIOLET;
        border: none;
        background-color: transparent;
        transform: translateY(3px);
        cursor: pointer;

        // very bad
        .notification-btn__marker {
            position: absolute;
            top: -6px;
            right: -5px;
        }
    }

    &__chat-bot {
        margin-top: 20px;
    }

    &__counter {
        width: 17px;
        height: 17px;
        background-color: $CARNATION;
        border-radius: 50%;
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        color: $WHITE;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: -6px;
    }

    &__btn {
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: block;
    }

    &__modal-refill-balance {
        flex: 0 1 480px;
    }
}

@include respond-to(mobile-landscape) {
    .header {
        padding: 25px 16px;
        backdrop-filter: blur(0);
    }
}


.payment-block {
    $this: &;

    padding: 24px;
    color: $DARK_VIOLET;

    &--dark {
        #{$this}__variant {
            color: $WHITE;
        }
    }

    &__title {
        font-size: 18px;
        font-weight: 600;
    }

    &__row {
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &__variant {
        display: flex;
        align-items: center;
        height: 40px;
        column-gap: 8px;
        cursor: pointer;
    }

    &__field {
        &--active {}
    }

    &__select {
        max-width: none;

        &--active {
            background-color: $BLUE_DE_FRANCE;
            color: $WHITE;
        }
    }

    &__input {
        width: 100%;
        height: 40px;
        padding: 10px;
        border-radius: 2px;
        border: 1px solid #E9EBF0;
        background: #FFF;
        font-size: 14px;
        color: $DARK_VIOLET;
        outline-color: $BLUE_DE_FRANCE_DARK;
    }

    &__button {
        margin-left: auto;
        outline: none;
    }

    &__buttons {
        display: flex;
        column-gap: 8px;
        justify-content: flex-end;
    }

    &__error {
        margin-top: 5px;
        display: inline-block;
        color: $RED;
        font-size: 14px;
    }
}

.chat-bot {
    &__title,
    &__link {
        font-size: 14px;
    }

    &__title {
        display: flex;
        align-items: center;
        color: $DARK_VIOLET;
    }

    &__link {
        display: inline-block;
        margin-top: 10px;
        text-decoration: none;
        color: $BLUE_DE_FRANCE;
    }

    &__icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
    }
}
