.editor--dark .tox .tox-edit-area__iframe {
    background-color: #2B343B !important;
}

.editor--dark {
    color: #fff !important;
}

.editor {
    margin: 16px 0 24px;

    p + p {
        margin-top: 0.75em;
    }

    // .ql-toolbar {
    .tox-editor-header {
        position: sticky;
        z-index: 2;
        top: -17px;
        left: 0 !important;
        background-color: #fff;
    }

    &--dark {
        .ql-editor {
            background-color: #2B343B;
        }

        .ql-toolbar.ql-snow {
            background-color: #1e1e1e;
            border-color: #1e1e1e;
        }

        .ql-container.ql-snow {
            // border-color: #1e1e1e;
            border: none;
        }

        .ql-picker-label {
            color: #fff;

            svg polygon {
                stroke: currentColor;
            }
        }

        .ql-formats svg polyline,
        .ql-formats svg line,
        .ql-formats svg path:not(.ql-fill) {
            stroke: #fff;
        }

        .ql-formats svg .ql-fill {
            fill: #fff;
        }

        .ql-formats .ql-image svg rect,
        .ql-formats .ql-image svg polyline {
            stroke: #fff;
            fill: none;
        }

        .ql-formats .ql-image:hover svg polyline {
            stroke: none;
        }

        .ql-formats .ql-blockquote svg rect,
        .ql-formats .ql-blockquote svg path {
            stroke: #fff;
        }
    }
}

.ql-snow .ql-editor img {
    display: inline;
}

.ql-tooltip.ql-editing {
    //left: 0 !important;
}

.ql-editor .ql-video {
    display: inline-block;
}

@media (max-width: 768px) {
    .editor {
        .ql-toolbar {
            top: var(--task-name-height, 24px);
        }
    }

    // here are styles for header selecting label
    .ql-snow.ql-toolbar .ql-formats:nth-of-type(4) .ql-picker-label {
        font-size: 16px;
    }

    .ql-snow.ql-toolbar .ql-color-picker,
    .ql-snow.ql-toolbar button {
        width: 30px;
        height: 28px;
        padding: 4px;
        font-size: 16px;
    }

    .ql-snow.ql-toolbar .ql-color-picker {
        padding: 0;
    }
}

@media screen and (max-width: 468px) {
    .ql-toolbar.ql-snow .ql-formats {
        margin-right: 8px;
    }
}
