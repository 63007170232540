@import "../../styles/abstracts/colors.scss";
@import "../../styles/abstracts/mixin.scss";

.settings-panel {
    $this: &;

    &--dark {
        #{$this}__icon * {
            fill: $SILVER;
        }
    }

    #{$this}__modal-header {
        background-color: $WHITE;
    }

    &__modal {
        max-width: 480px;
    }

    &__modal-balance {
        width: 100vw;
        max-width: 600px;
    }

    &__body {
        padding: 24px;
    }

    &__title {
        margin-bottom: 20px;
        font-size: 18px;
        font-weight: 600;
        color: $DARK_VIOLET;
    }

    &__error {
        margin-top: 5px;
        font-size: 14px;
        color: $RED;
    }

    &__item {
        display: flex;
        margin-left: 25px;
        cursor: pointer;
    }

    &__button {
        margin-top: 16px;
        margin-left: auto;
    }

    &__icon * {
        fill: currentColor;
    }
}

.project-settings {
    $this: &;

    height: 100%;

    &--dark {
        #{$this}__item {
            color: $WHITE;
        }
    }

    &__modal-body {
        height: calc(100% - 42px);
    }

    &__content {
        overflow-x: auto;
        display: flex;
        flex-direction: column;
        padding: 24px;
        height: 100%;

        @include mediaMax(768px) {
            height: calc(100% - 53px);
        }
    }

    &__top {
        padding-bottom: 24px;
        margin-bottom: 20px;
        border-bottom: 1px solid #E9EBF0;
    }

    &__item {
        display: flex;
        align-items: center;
        column-gap: 8px;
        color: $SCARPA_FLOW;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        transition: color .1s ease-in-out;

        path {
            fill: currentColor;
            transition: fill  .1s ease-in-out;
        }

        &:not(:last-of-type) {
            margin-bottom: 16px;
        }

        &:hover {
            color: $BLUE_DE_FRANCE;
        }
    }

    &__title {
        margin-bottom: 4px;
        color: $SILVER;
        font-size: 16px;
        font-weight: 400;

        &--dark {
            color: $WHITE;
        }
    }

    &__button-save {
        margin-top: 16px;
        margin-left: auto;
    }

    &__button-delete {
        margin-top: auto;
        margin-left: auto;
        margin-right: auto;
        font-size: 16px;
        color: $SILVER;
        text-decoration: underline;
        cursor: pointer;
    }

    &__input {
        border-radius: 2px;
        border: 1px solid $GALLERY;
        background: $WHITE;
        height: 34px;
        width: 100%;
        display: block;
        outline: none;
        padding: 7px 10px 8px;
    }
}

.archive-block {
    $this: &;

    min-width: 1140px;

    &--dark {
        #{$this}__col--name {
            color: $WHITE;
        }
    }

    &__content {
        overflow-y: auto;
        max-height: calc(100vh - 120px);
    }

    &__header {
        display: flex;
        align-items: center;
        margin-top: 19px;
        margin-bottom: 18px;
        padding-top: 6px;
        padding-bottom: 6px;

        #{$this}__col {
            height: auto;
            padding-top: 0;
            padding-bottom: 0;
            font-size: 14px;
            font-weight: 500;
            text-transform: uppercase;
            color: $SILVER;
        }
    }

    &__item {
        &-sub-ttl {
            margin-bottom: 4px;
            color: $SCARPA_FLOW;

            &:nth-of-type(2) {
                height: 40px;
            }
        }

        svg path {
            fill: currentColor;
        }

        & + & {
            margin-left: 12px;
        }

        &-inner {
            position: relative;
            display: inline-flex;
            align-items: center;

            & + & {
                margin-left: 8px;
            }

            span {
                position: absolute;
                z-index: 1;
                left: 16px;
                font-size: 13px;
                color: #000;
            }
        }

        .custom-select {
            min-width: 240px;
        }

        .react-datepicker__input-container {
            span {
                position: absolute;
                width: 30px;
                height: 30px;
                background-color: red;
            }

            input {
                width: 100%;
                height: 40px;
                padding: 0 16px;
                padding-left: 46px;
                border-radius: 2px;
                border: 1px solid #E9EBF0;
                background: #FFF;
                font-size: 13px;
                outline-color: #3F8CFF;

                &::placeholder {
                    color: $SILVER;
                }
            }
        }

        .react-datepicker-wrapper {
            width: 240px;
        }
    }

    &__button-search {
        padding: 8px;
        margin-left: 24px;
        height: 40px;
        align-self: flex-end;
    }

    &__body {}

    &__row {
        display: flex;
        align-items: center;
        margin: 3px;
        border-radius: 2px;
        box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
        cursor: pointer;
        transition: background-color .1s;

        & + & {
            margin-top: 16px;
        }

        &:hover {
            background-color: rgba(63, 140, 255, 0.06);
        }
    }

    &__col {
        width: 100px;
        flex: 1;
        display: flex;
        align-items: center;
        flex-basis: 14%;
        min-height: 51px;
        padding: 10px 40px;
        font-size: 14px;
        font-weight: 400;

        &--project,
        &--name {
            color: $DARK_VIOLET;

            // > div {
            //     @include textOverflow(2);
            // }
       
        }

        &--project {
            max-width: 160px;
            padding-right: 0;
            font-size: 14px;
            font-weight: 500;
            text-align: left;
        }

        &--name {
            // flex-basis: 230px;
            max-width: 430px;

            // flex: auto;
            // width: 100%;
            padding-right: 0;
            word-break: break-all;
        }

        &--date-start,
        &--date-end,
        &--rating,
        &--comment,
        &--show {
            justify-content: center;
        }

        &--date-start {
            margin-left: 3%;
        }

        &--date-end {
            // margin-left: 3%;
            color: $SOFT_RED;
        }

        &--rating,
        &--comment,
        &--show {
            margin-left: 3%;
            flex-basis: 90px;
        }
    }

    &__tooltip-comment {
        display: block;
        max-width: 216px;
    }
}

.personal-cabinet {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: -16px;
        right: -16px;
        width: calc(100% + 32px);
        top: 40px;
        height: 0;
        border-bottom: 1px solid #E9EBF0;
        z-index: -1;
    }

    &__header {
        border-bottom: 1px solid $GALLERY;
    }

    &__profile {
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
    }

    &__modal {
        max-width: 480px;
    }

    &__modal-body {
        padding: 16px;
    }

    &__rename {
        display: grid;
        row-gap: 16px;
    }

    &__rename-buttons {
        display: flex;
        justify-content: flex-end;
        column-gap: 16px;
        margin-top: 16px;
    }

    &__sub-title {
        margin-bottom: 8px;
        font-size: 16px;
        color: $DARK_VIOLET;
    }

    &__input {
        width: 100%;
        height: 35px;
        padding-left: 15px;
        border-radius: 2px;
        border: 1px solid #E9EBF0;
        background: #FFF;

        &:active,
        &:focus {
            outline-color: #3F8CFF;
        }
    }

    &__change-password {}

    &__password-button {
        margin-left: auto;
        margin-top: 16px;
    }
}

.user-profile {
    $this: &;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px 20px;

    &--dark {
        #{$this}__title,
        #{$this}__tel,
        #{$this}__social,
        #{$this}__email {
            color: $SILVER;
        }
    }

    &__top {
        text-align: center;
    }

    &__top,
    &__bottom {
        width: 100%;
    }

    &__tel {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    &__avatar {
        @include mxAuto;

        position: relative;
        display: block;
        width: 100px;
        height: 100px;
        margin-bottom: 8px;
        border-radius: 50%;
        background-color: $LIGHT_GRAY;

        &:hover {
            cursor: pointer;
        }

        &:hover #{$this}__button-avatar {
            opacity: 1;
            backdrop-filter: blur(1px);
        }

        img {
            position: absolute;
            inset: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            max-width: 100%;
            border-radius: 50%;
        }

        &-input {
            display: none;
            width: 0;
            height: 0;
        }
    }

    &__button-avatar {
        position: absolute;
        inset: 0;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 0;
        border-radius: inherit;
        transition: opacity .1s ease-in;
    }

    &__avatar-label {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__edit {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 3;
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $WHITE;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    }

    &__avatar-icon {
        width: 50%;
        height: 100%;
        fill: $WHITE;
    }

    &__title,
    &__text {
        margin-top: 4px;
        color: $DARK_VIOLET;
    }

    &__title {
        @include mxAuto;

        position: relative;
        width: max-content;
        font-size: 18px;
        font-weight: 700;
        cursor: pointer;

        &--staff {
            font-size: 14px;
        }

        #{$this}__edit-icon {
            position: absolute;
            right: -24px;
            bottom: 3px;
        }
    }

    &__text {
        cursor: pointer;
    }

    &__button {
        margin-top: 24px;
        width: 100%;
        display: flex;
        align-items: center;
        column-gap: 10px;
        background-color: $SILVER;
        color: $WHITE;

        svg {
            fill: currentColor;
        }
    }

    &__edit-icon {}

    &__social {
        display: flex;
        align-items: center;
        column-gap: 6px;
        font-size: 16px;
        font-weight: 700;
        color: $DARK_VIOLET;
    }

    &__socials {
        margin-top: 42px;
        display: flex;
        column-gap: 10px;
        justify-content: space-between;
    }

    &__settings {
        padding: 24px 0;
    }
}

.toggler {
    $this: &;

    --offset: 2px;
    --active-bg: #3F8CFF;
    --size: 26px;
    overflow: hidden;
    display: block;
    position: relative;
    width: calc(var(--size) * 1.9);
    height: var(--size);
    padding: var(--offset);
    border-radius: 4px;
    cursor: pointer;

    &--rounded {
        border-radius: 15px;

        #{$this}__overlay {
            &::after {
                border-radius: 50%;
            }
        }
    }

    &__checkbox {
        opacity: 0;
        width: 0;
        height: 0;
    }

    &__checkbox:checked + &__overlay {
        background-color: var(--active-bg);

        &::after {
            // transform: translateX(100%);
            margin-left: calc(100% - 50%);
        }
    }

    &__overlay {
        position: absolute;
        z-index: 1;
        inset: 0;
        padding: var(--offset);
        border-radius: 4px;
        background-color: #EFEFEF;
        transition: background-color 0.3s ease;

        &::after {
            content: '';
            display: block;
            width: 50%;
            height: 100%;
            filter: drop-shadow(0px 3px 1px rgba(0, 0, 0, 0.06)) drop-shadow(0px 3px 8px rgba(0, 0, 0, 0.15));
            background-color: $WHITE;
            aspect-ratio: 1;
            border-radius: 4px;
            transition: margin-left 0.3s ease;
        }
    }

    &--xs {
        --size: 15px;
    }

    &--sm {
        --size: 20px;
    }

    &--lg {
        --size: 30px;
    }
}

.user-setting {
    $this: &;

    margin-bottom: 12px;
    color: $DARK_VIOLET;

    &--dark {
        color: $WHITE;

        #{$this}__checkbox:checked + svg path + path {
            fill: $WHITE;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        column-gap: 4px;
        margin-bottom: 12px;
    }

    &__option {
        position: relative;
        display: flex;
        column-gap: 6px;
        padding-left: 7px;
        margin-bottom: 12px;
        cursor: pointer;
    }

    &__checkbox {
        width: 0;
        height: 0;
        opacity: 0;

        &:not(:checked) + svg path {
            display: initial;
        }

        &:not(:checked) + svg path + path {
            display: none;
        }

        & + svg path {
            display: none;
        }

        & + svg path {
            display: none;
        }

        &:checked + svg path + path {
            display: initial;
        }
    }

    &__button {
        margin-left: auto;
        margin-top: 24px;
    }
}

.avatar-action {
    display: flex;
    align-items: center;
    column-gap: 5px;

    &__icon {
        width: 26px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $WHITE;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
        transition: transform .1s ease-in-out;
        cursor: pointer;

        &--delete {
            &:hover svg path {
                fill: $SOFT_RED;
            }
        }

        svg {
            width: 60%;
            height: 60%;
        }

        svg path {
            transition: fill .1s ease-in-out;
            fill: $DARK_VIOLET;
        }
    }
}

.filter-container {
    padding: 16px;
}

.filter-item {
    display: block;
    color: $DARK_VIOLET;
    cursor: text;

    &:not(:last-of-type) {
        margin-bottom: 16px;
    }

    .custom-select__placeholder {
        color: $SILVER;
    }
}

.filter__input {
    margin-top: 8px;
    display: block;

    &::placeholder {
        color: $SILVER;
    }
}

.filter-button {
    margin-left: auto;
    margin-top: 16px;
}
